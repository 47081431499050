import en, { TranslationMessages } from './en';
import flattenMessages from '@liveauctioneers/caterwaul-components/lib/utils/flattenMessages';

/* eslint-disable sort-keys/sort-keys-fix, max-len */
const enGb: TranslationMessages = {
    // pages
    aboutUs: {
        content1:
            'LiveAuctioneers brings an international audience of millions to the heart of the bidding action in {art}, antiques, {jewelry} and {collectibles} auctions across the globe.',
        content4:
            'LiveAuctioneers revolutionised the industry from the start. In 2002, the NYC-based company formed a marketing partnership with eBay to introduce eBay Live Auctions. This alliance of LiveAuctioneers and eBay enabled auction houses worldwide to go online with their live sales—a development that changed the auction business forever. In 2009, the debut of {apps}, with live-bidding capabilities, opened up a new mobile pipeline to bid anytime, from anywhere, with complete anonymity. Since then, LiveAuctioneers has been the first to engage bidders with live streaming video, easy online payments, personalised search alerts, and more.',
    },

    // Head Data
    default_title: 'LiveAuctioneers: Online Auctions for Arts, Antiques & Collectables',

    // Navigation
    navigation: {
        jewelry: 'Jewellery',
        collectibles: 'Collectables',
    },
    help_center: 'Help Centre',
    no_bid_reason:
        "Online bidding isn't available for this catalogue. To request online bidding {link} and you’ll be notified if bidding is made available.",
    request_submitted_long: "We'll send you an email if the catalogue begins accepting bids.",
    use_our_apps_to_bid:
        'Use our apps to bid on thousands of antique, fine art, and collectable items from around the world. Preview items in upcoming auctions, place bids in advance, watch and bid on live auctions and more—no matter where you are.',
    auction_results_description:
        'LiveAuctioneers’ Auction Price Results Database is a free research tool for those who want to buy or sell. Access 21 million art, antique, jewellery, furniture, collectable, and fashion results that were auctioned off from thousands of global auction houses. Search by keyword and refine by auction house, location, origin, style & period, creator, materials, techniques, and more to research and determine the value of items in your existing or future collection. Auction results are updated daily with new hammer prices so you can always access recently sold to historical results dating back to 1999. Whether you’re a collector wanting to know what price to pay; a consignor looking to learn the value of your property; an appraiser or estate office trying to evaluate a client’s possessions; or a journalist researching a given subject, our database is a valuable tool.',
    why_cant_i_view_the_catalog: "Why can't I view the catalogue?",
    this_catalog_is_an_auction_preview:
        'This catalogue is an Auction Preview and does not contain any lots. To be updated when it does have lots clicked onto the Follow Catalogue Updates button above.',
    seller_resource_center: 'Seller Resource Centre',
    empty_save_message_part_2:
        "You can keep up to date with your favourite auctioneers by pressing the 'Follow' button on their auctioneer profile page.",
    category_root_description:
        'Free online bidding auctions available worldwide. Find online auctions for vintage jewellery, artwork, furniture and many other exceptional antiquities.  Available are local auctions online and pick up in-store, or arrange shipping from auctions near you. Thousands of auctions are ending every month, find your treasure.',
    comment1_2:
        'You shared our passion about the auction, worked hard (even on nights and weekends) and relentlessly pursued every possible PR lead. We really don’t know how to thank you; you have energised and set the new standard for Palm Beach Modern Auctions.',
    feature_blurb:
        'Live online auctions deliver a powerful trifecta: the potential of thousands of new customers, increased average hammer prices and dramatically fewer unsold items. Using LiveAuctioneers tools and features, you can track and analyse performance while tapping into the global Internet marketplace to build your business:',
    auctioneer_dashboard_blurb:
        "The go-to for everything involving a LiveAuctioneers account. From step-by-step auction set-up to bidder registration and approval, it's all organised, along with email messaging, access to marketing services and helpful guides and forms, just a click away.",
    our_network_blurb:
        'Our live network runs on multiple server clusters located in two different regions of the US to ensure redundancy. Our East Coast and Southern US data centres have fully redundant battery power, generator back-up and massive connectivity, utilizing such reliable network providers as Level 3, Global Crossing and Congent. A team of technologists dedicated to network and server maintenance ensures the reliability of a complex custom system configuration to handle millions of live bidding transactions daily.',
    catalog_set_up: 'Catalogue Set-up',
    catalog_set_up_blurb:
        'Item descriptions, images, estimated selling prices and contact details encourage visitors to view your catalogues before registering to bid. After a catalogue has been published on LiveAuctioneers, registered bidders can view items and leave absentee bids, or wait to bid live on the day of the sale.',
    inbox_messaging_blurb:
        'This makes communication with bidders easy and centralised, offering you the chance to receive and reply to bidder questions throughout the auction process. All bidder conversations are threaded, linked to bidder profiles, and can be sorted info folders.',
    self_service_upload_blurb:
        'Makes it possible for you to upload images and catalogues directly into the LiveAuctioneers system 24/7.',
    live_items_widget: 'Live Items Widget and Live Catalogue Widget',
    live_items_widget_blurb:
        'Live Items Widget displays live items directly on the your website in a 220x200 window. Live Catalogue Widget displays the entire catalogue on your website in the gallery layout.',
    qr_code_blurb:
        'A QR code image can be added to your print ads, allowing smart phone users to go directly to your catalogue.',
    bidder_contact_and_invoicing_blurb:
        "This feature enables you to contact winning bidders with customised emails or invoices within minutes of the auction close, avoiding the time-consuming task of invoicing. LiveAuctioneers' fully automated system also allows a buyer to complete his/her purchase online.",
    white_label_platform_blurb:
        'This option allows you to customise the live bidding console and run live auctions directly from your website.',
    find_exceptional_fine_art: 'Find exceptional fine art, collectables, and décor in online auctions',
    browse_collectibles: 'Browse collectables',
    // bidding console
    bidding_console: {
        console: {
            button: {
                backToLiveItem: 'Back To Live Item',
                addPaymentMethod: 'Add Payment to Place Bid',
                login: 'Log In To Bid',
                signup: 'Sign Up To Bid',
                register_long: 'Register For Auction',
                pending: 'Registration Pending',
                declined: 'Registration Declined',
                suspended: 'Account Suspended',
                closed: 'Lot Closed',
                sent: 'You sent {amount} bid',
                outbid: 'Outbid! Bid {amount}',
                bid: 'Bid {amount}',
                winning: "You're Winning",
                waiting: 'Still waiting on approval?',
                limit_exceeded: 'This item would exceed your bid limit of {limit}',
                approved_limited: "You're approved to bid up to {limit} and have {balance} remaining.",
                show_settings: 'Show Settings',
                hide_settings: 'Hide Settings',
                bidding_locked: 'Bidding Locked',
                bidding_unlocked: 'Bidding Unlocked',
            },
            conversions: {
                lot_number: 'Lot {lotNumber}',
            },
            currentLiveItem: {
                auctioneerInfo: {
                    buttons: {
                        askAQuestion: 'Ask a Question',
                        follow: 'Follow',
                        following: 'Following',
                        followingAuctioneer: 'Following Auctioneer',
                        followAuctioneer: 'Follow Auctioneer',
                    },
                },
                buyersPremium: {
                    middleLowCutOff: '{middle}% above',
                    middleCutOff: '{middle}% up to',
                    highCutOff: '{high}% above',
                    lowCutOff: '{low}% up to',
                    lowPercentage: '{low}%',
                },
                curator: {
                    curatedBy: 'Auction Curated By',
                },
                headers: {
                    buyersPremium: "Buyer's Premium",
                    condition: 'Condition',
                    description: 'Description',
                    dimensions: 'Dimensions',
                    itemDetails: 'Item Details',
                    weight: 'Weight',
                },
            },
            description: {
                estimate_range: 'Est. {low} - {high}',
                images_label: '{currentImageNumber} / {totalImages}',
                single_estimate: 'Est. {number}',
                currentBid: 'Current Bid: {amount}',
                convertedBid: 'Conversion: {amount}',
            },
            lotTracker: {
                bid: 'Bid',
                bids: 'Bids',
                liveNow: 'Live Now',
                soldFor: 'Sold for',
                passed: 'Passed',
            },
            messages: {
                ask_price_changed_to: 'Ask Price Changed: {amount}{converted}',
                bid_limit_exceeded: 'Your bid limit exceeded',
                competing_bid_amount: '{amount}{converted}: Competing Bid',
                competing_bidder_outbid_by_liveauctioneers_absentee:
                    "Competing Bidder was Outbid by Bidder {paddleNumber}'s Absentee Bid",
                last_bid_retracted: 'Last bid of {amount}{converted} retracted',
                limited_approval: 'Approved to bid up to: {limit}',
                liveauctioneers_bid_amount: '{amount}{converted}: LiveAuctioneers Bidder {paddleNumber}',
                liveauctioneers_bid_amount_absentee:
                    "{amount}{converted}: LiveAuctioneers Bidder {paddleNumber}'s Absentee Bid",
                lot_number_passed: 'Lot {lotNumber} Passed',
                lot_number_reopened: 'Lot {lotNumber} Re-Opened',
                lot_number_skipped: 'Lot {lotNumber} Skipped',
                lot_number_unsold: 'Lot {lotNumber} Unsold',
                lot_opened_asking: 'Lot {lotNumber} Opened - Ask {amount}{converted}',
                lot_sold_competing_bidder: 'Lot {lotNumber} sold for {amount}{converted} to Competing Bidder',
                lot_sold_liveauctioneers_bidder:
                    'Lot {lotNumber} sold for {amount}{converted} to Bidder {paddleNumber}',
                my_bid_amount: '{amount}{converted}: My Bid',
                my_bid_amount_absentee: '{amount}{converted}: My Absentee Bid',
                my_bid_retracted: 'Your bid for {amount}{converted} was retracted',
                paused: 'Auction Paused',
                registration_approved: 'Your registration has been approved',
                reserve_not_met: 'Reserve Not Met',
                you_been_outbid_by_liveauctioneers_absentee:
                    "You've Been Outbid by Bidder {paddleNumber}'s Absentee Bid",
                you_ve_won_lot: "You've won Lot {lotNumber}",
            },
            settingsMenu: {
                headerText: 'Settings',
                language: 'Language',
                currency: 'Currency',
            },
            missives: {
                fair_warning_lot_about_to_close: 'Fair Warning... This lot is about to close',
                going_once_going_twice: 'GOING ONCE...GOING TWICE...',
                third_and_final_warning: 'THIRD AND FINAL WARNING...',
            },
            status: {
                remaining: '{remaining} of {total} Lots Remaining',
                nextBid: {
                    text: 'My Next Bid',
                    value: '{total} Away',
                },
                nextFavorite: {
                    text: 'My Next Favorite',
                    value: '{total} Away',
                },
            },
            thumbnail_scroller: {
                next: 'Next Lots',
            },
            lockText: {
                locked: 'Unlock to Bid',
                unlocked: 'Bidding Unlocked',
            },
            mobileHeader: {
                title: '{lotNumber}: {title}',
            },
            mobileFooter: {
                itemSaves: '{savedCount}',
            },
        },
        explainer_modal: {
            title: 'Welcome to the Auction',
            subtext: 'Good luck! Please review some auction details.',
            fee_disclaimer:
                'The price of your bid does not include potential fees, such as a buyer’s premium, taxes, shipping, or processing fee',
            autopay_disclaimer: 'You will be charged the full price shortly after the conclusion of the auction',
            shipping_disclaimer: 'Shipping details can be found in this auction’s Shipping, Payment & Auction Policies',
            button: 'Got it',
        },
        wait: {
            title: 'Stay tuned. Auction will begin shortly',
            status_message: "{name} is delighted you're here!",
            login_button_text: 'Log In',
            register_button_text: 'Register for Auction',
            image_slider_text: 'Popular Lots in This Auction',
            hidden_text: 'View the Catalogue',
            card: {
                title: 'Lot {lotNumber}: {title}',
                bid: 'Bid',
                bids: 'Bids',
            },
        },
        done: {
            title: '{title} has ended',
            status_message: 'Follow {name} to hear about their next auction.',
            live_catalogs_title: 'View Other Live Auctions',
            upcoming_catalogs_title: 'Upcoming Auctions',
        },
    },
    view_the_catalog: 'View the Catalogue',
    // Checkout Page
    checkout: {
        page: {
            description:
                'Get in on the auction — bid LIVE online on art, antiques, jewellery and collectables. Find online auctions from around the world at LiveAuctioneers.',
        },
    },
    registration: {
        preview_only_description:
            "This catalogue is only available for preview on LiveAuctioneers. Request online bidding for this auction, and we'll let you know if this catalogue begins accepting bids on LiveAuctioneers.",
    },
};

export default { ...en, ...flattenMessages(enGb) };
