import {
    AccountHolderWalkthroughSteps,
    DONE,
    FirstTimeWalkthroughSteps,
    NewAccountWalkthroughSteps,
    OnboardingWalkthroughSteps,
} from '@/types/walkthroughs';
import {
    ContentKeys,
    InfoMessageKeys,
    ItemPageTopicKeys,
    MessageKeys,
    MessageListKeys,
    TitleKeys,
    WonItemsPageTopicKeys,
} from '@/components/Modals/GetHelp/enums/MessageKeys.enum';
import { CustomShippingRateModalPages } from '@/components/ShippingInfo/LiveShippingInfo/components/ShippingPickupOptions/components/LiveShipping/components/CustomShippingRateModal/hooks/useCustomShippingRateModalPages';
import { DeepPartial } from 'utility-types';
import { en as hammerLAIntlEn } from '@liveauctioneers/hammer-ui-liveauctioneers/intl/en';
import { nationalLogisticServices } from '@/intl/partnerShippingPolicies/nationalLogisticServices';
import { packengers } from '@/intl/partnerShippingPolicies/packengers';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import { ShippingMethods } from '@/enums/shippingMethod';
import { TimedPlusStatus } from '@/components/ItemBidding/components/Estimate/components/Estimate-12hCountdownTimer/components/TimedPlusCountdown/types/TimedPlusStatus.enum';
import {
    UserBidPlacedItemsBidStatusEnum,
    UserBidPlacedItemsSortEnum,
    UserBidPlacedItemsStatusEnum,
} from '@/types/item/UserBidPlacedItem';
import caterwaulIntlEn from '@liveauctioneers/caterwaul-components/lib/Intl/intl/en';
import flattenMessages from '@liveauctioneers/caterwaul-components/lib/utils/flattenMessages';

/* eslint-disable sort-keys/sort-keys-fix, max-len, no-template-curly-in-string */
const onboarding: { [key in OnboardingWalkthroughSteps]: string } = {
    [`${FirstTimeWalkthroughSteps.Landing}Title`]: 'Welcome to LiveAuctioneers',
    [FirstTimeWalkthroughSteps.Landing]:
        'New to online auctions? Learn about how auctions work on LiveAuctioneers, or visit our Help Center at any time.',
    [FirstTimeWalkthroughSteps.FutureItem]:
        'You can place your absentee bids before the live auction begins. For the best chance at winning, make sure to also bid during the live auction.',
    [FirstTimeWalkthroughSteps.LiveItem]:
        'For the best chance at winning, enter the live auction to bid in real time. You can also place absentee bids from this page.',
    [FirstTimeWalkthroughSteps.Search]:
        'Try switching to the Price Results view to see how much these sold for in the past.',
    [AccountHolderWalkthroughSteps.Returning]:
        "Ready to start hunting for one-of-a-kind art, antiques, and collectibles? Join LiveAuctioneers (it's free) and access thousands of auction treasures added daily.",
    [NewAccountWalkthroughSteps.FirstTimeAnyPage]:
        'You can customize email preferences, view won items, and more in your account controls.',
    [NewAccountWalkthroughSteps.Item1]:
        'Take a quick tour to familiarize yourself with this auction item. Click start below to begin.',
    [NewAccountWalkthroughSteps.Item2]:
        'Go to View Auction to browse and search all items available in this auction. Here you will also find general information about the sale.',
    [NewAccountWalkthroughSteps.Item3]:
        'The Final Price Estimate is provided by the auction house and is the estimated range the item is expected to sell for.',
    [NewAccountWalkthroughSteps.Item4]:
        'Auction houses set their own shipping policies. Avoid surprises and review the shipping policy before you bid.',
    [NewAccountWalkthroughSteps.Item5]:
        "The Buyer's Premium is a fee collected by the auction house, paid by the winning bidder. Independent auction houses rely on Buyer's Premiums to help cover the costs of running their businesses.",
    [DONE]: 'Done',
};

/* eslint-disable sort-keys/sort-keys-fix, max-len, no-template-curly-in-string */
export const en = {
    main: {
        auctionCentralNews: 'Auction Central News',
        auctionResultsDatabase: 'Auction Results Database',
        consignmentsHub: 'LiveAuctioneers Consignment Hub',
    },

    savedItemTabs: {
        upcoming: 'Upcoming',
        past: 'Past',
    },

    savedSearches: {
        atLeast: 'At least',
        atMost: 'At most',
        within: 'within',
    },

    toastMessages: {
        titles: {
            success: 'Success',
            info: 'Info',
            error: 'Error',
        },
        descriptions: {
            completeVerification: 'Thanks for verifying your phone number!',
            copiedToClipboard: 'Copied to clipboard!',
            conversationMovedToFolder: 'Conversation moved to {folderName}',
            searchSaved: 'Search Saved!',
            unsubscribeAllEmailPreference: '{email} has been opted out of all newsletters',
            unsubscribeEmailPreference: '{email} will no longer receive {alert} emails',
            unsubscribeEmailAlreadyPreference: '{email} is already unsubscribed from {alert} emails',
            unsubscribeEmailDontRecognize: "Sorry we don't understand that subscription code.",
            globalNotificationPageReloadSuggestion:
                'Please enjoy the latest version of this page by refreshing your browser.',
            globalNotificationAutomaticPageReloadWarning:
                'This page will automatically refresh in 60 seconds so you can enjoy the latest version of our website.',
            followingSearch:
                'Following Search! We will send you email alerts when new items matching your criteria are available at auction.',
        },
    },

    // Blocked by Waf
    blocked: {
        back: 'Back To Home',
        beenBlocked: 'You have been blocked',
        copied: 'Copied',
        errorCode: 'Error Code:',
        thisWebsite:
            'This website is using a security service to protect itself from online attacks. The action you just performed triggered the security solution. There are several actions that could trigger this block including submitting a certain word or phrase, a SQL command, or malformed data.',
        whatCanIDo: 'What can I do to resolve this?',
        why: 'Why have I been blocked?',
        yourIp: ' -Your IP:',
        youCanEmail:
            'You can email {link} to let them know you were blocked. Please include what you were doing when this popup appeared and the Error Code found at the bottom of this popup.',
    },

    // components
    bankAccount: {
        pendingVerification: 'Pending Verification',
        verify: 'Verify',
    },
    bidLimitExceededMessage: {
        linkText: 'sending a message',
        subject: 'Bid limits',
        text: '{sellerName} sets the maximum you can bid when you register.  If you are interested in bidding more, please reach out to {sellerName} by {link} or calling {sellerPhone}',
    },
    buyNowSummary: {
        buyersPremium: "Buyer's Premium:",
        itemPrice: 'Item Price:',
        quotePending: 'Quote Pending',
        shipping: 'Shipping:',
        total: 'Total:',
        totalPlusShipping: '{total} + Shipping',
    },
    featuredAuctioneers: {
        featured: 'Featured Auctioneers',
    },
    forgotPassword: {
        emailAddress: 'Email Address',
        error: 'Please enter a valid email address.',
        sendResetLinkToSMS: 'Also send the reset link to my phone number on file',
        submit: 'Send Reset Instructions',
    },
    forgotPasswordConfirmation: {
        help: 'If you haven’t received an email within a few minutes, check your spam folder. You can also {link} once every five minutes. Only the most recent reset email will work.',
        helpLink: 'resend the email',
        text: 'We sent you an email with instructions on how to reset your password.',
    },
    itemBidding: {
        bid: '({bidCount} {bidCount, plural, one {bid} other {bids}})',
        endsInDays: 'Ends in {days}d {hours}h',
        endsInTime: 'Ends in {hours}h {minutes}m',
        endsInTimeMinutes: 'Ends in {minutes}m {seconds}s',
        endsInTimeSeconds: 'Ends in {seconds}s',
        endsIn: 'Ends in {hours}h {minutes}m',
        currentPrice: 'Current Price ({bidCount} {bidCount, plural, one {bid} other {bids}})',
        lotsAway_short: '{lotsAway} Lots Away',
        lotAway_short: '1 Lot Away',
        estimate: 'Estimate {low} - {high}',
        passedText: 'Lot Passed',
        lotNumber: 'lot {lotNumber}',
        lotAgo: 'Closed 1 Lot Ago',
        lotClosedTimedPlus: 'Lot Closed {tooltip}',
        lotsAgo: 'Closed {lots} Lots Ago',
        lotAway: 'Get Ready! 1 Lot Away',
        lotsAway: 'Coming Up: {lots} Lots Away',
        liveNow: 'Live Now',
        startingPrice: 'Starting Price',
        leftToBid: 'Only {count} seconds left to bid!',
        dontWait:
            "Don't wait until the last second to bid!  To give all bidders a fair chance at winning, bidding is extended when additional bids are placed in the final moments of the auction.",
        bidBoxButton: {
            saveItem: 'Save',
            itemSaved: 'Saved',
            messageSeller: 'Message Seller',
            messageSellerShort: 'Message',
        },
    },
    shippingDetails: {
        inquireInternational: 'Ask for international & combined shipping rates',
        shippingToUS: '{domesticShippingCost} shipping to contiguous United States',
        shipsFromAgent: 'Ships from verified Jasper52 seller #{agentId}',
        shipsFromAgentIn: 'Ships from verified Jasper52 seller #{agentId} in {fromLocation}',
        freeConUS: 'Free {stateCode}',
    },
    // Item page, lower right shipping section
    shippingInfo: {
        shippingPoliciesModalTitle: 'Shipping, Payment & Auction Policies',
        shipsFromLabel: 'Ships from {location}',
        shipsFromJasper52: 'Ships from {location}',
        shippingLocation: '{city}, {state}, {country}',
        shippingLocationNoState: '{city}, {country}',
        itemLocatedIn: 'Item located in {location}',
        announcement: {
            announcementFrom: 'Announcement from {sellerName}',
            read: 'Read Auctioneer Announcement',
        },
        paymentTitle: 'Payment',
        localPickup: {
            localPickupLabel: 'Local Pickup Available',
            localPickupNotAvailableLabel: 'Pickup Not Available',
        },
        paymentMethods: {
            title: 'Accepted Payment Methods',
        },
        selfArranged: {
            title: 'Arrange Your Own Shipping',
        },
        shippingCost: {
            title: 'Shipping',
            seePolicyForShipping: 'See Policy for Shipping',
            offersInHouseShipping: 'Offers In-House Shipping',
        },
    },

    // item page terms & info
    itemTerms: {
        hasWeapons: 'Credit card payments are not accepted for firearms through LiveAuctioneers, no exceptions.',
    },

    taxExemptModal: {
        title: 'Tax-Exempt?',
        subtitle:
            'As a reseller, you’re eligible for tax exemption. It can take a couple days for your certificate to process, so make sure to add it to your account page as soon as possible.',
        upload: 'Upload Certificate',
        notExempt: "Doesn't Apply",
    },

    onTheHuntModal: {
        huntingFor: 'Hunting for {creatorName}?',
        onTheHunt: 'On the hunt?',
    },

    lightbox: {
        header: '{currentImage} of {galleryLength}',
    },

    imageGallery: {
        header: '{imageCount} Images',
    },

    // forms
    formErrors: {
        emailEmpty: 'Enter a valid email address.',
        mobileNumberInvalid: 'Enter a valid phone number.',
        none: '',
        offerEmpty: 'Enter a valid offer.',
        offerInvalid: 'Enter a valid offer.',
    },
    formLabels: {
        email: 'Email',
        firstName: 'First name',
        lastName: 'Last name',
        message: 'Message',
        messageOptional: 'Include Message',
        mobileNumber: 'Mobile Phone Number',
        offer: 'Your Offer',
        optional_parenthesis: '(optional)',
    },

    // modals
    smsConfirmationModal: {
        TOSTitle: 'SMS Terms of Service',
    },
    absenteeBidModal: {
        refreshAndTryAgain: {
            title: 'Please Refresh the Page and Try Again',
            body: "Sorry! Looks like there was a system error. Your bid hasn't been submitted. Please refresh the page and submit your bid again!",
            button: 'Refresh Page',
        },
        smsFooter: {
            title_unconfirmed: 'Never miss a treasure with SMS alerts.',
            title_confirmed: "You've activated SMS alerts!",
            termsOfService: `By opting into SMS alerts, you agree to receive SMS text messages on your mobile device.
            Your consent is not required as a condition of purchasing any goods or services from
            LiveAuctioneers. By agreeing to receive SMS messages from LiveAuctioneers, you certify that
            you are over 18 years of age and (a) you are the mobile account holder or (b) you have the
            account holder's permission to enroll the designated mobile phone number and can incur any
            mobile message or data charges. You agree that you are solely responsible for all message
            and data charges that apply to use of your mobile device to access LiveAuctioneers or use of
            a mobile application. All such charges are billed by and payable to your mobile service
            provider. Please contact your participating mobile service provider for pricing plans,
            participation status and details. You understand that wireless through Wi-Fi or a
            participating mobile service provider may not be available in all areas at all times and may
            be affected by product, software, coverage or other service changes made by your mobile
            service provider or otherwise. Additional terms and conditions may apply to your use of a
            mobile application, based on the type of mobile device on which you install and use the
            mobile application.`,
            body: 'Get texts when your item is up for auction and outbid. Customize automatic payment alerts and notifications. Msg & data rates may vary. Reply STOP to end.',
            label: 'I accept the {tocLink}.',
            cta: 'Receive SMS',
            confirm: {
                title: 'SMS alerts are enabled!',
                title2: "We'll send alerts to {phoneNumber}",
                manage: 'Manage Alerts',
            },
        },
        systemError: {
            title: "We Couldn't Submit Your Bid Due to a System Error",
            body: "Sorry! Our team is working to resolve the issue as soon as possible. You'll be able to resume bidding once the error has been fixed.",
            button: 'Close',
        },
    },
    addCreditCardModal: {
        genericHeader: 'Add New Card',
        completeAccountHeader: 'Add Payment Method',
        authErrorSubheader:
            'It seems there was an issue with your payment method on file. Please select another payment method to use for this auction.',
        header: 'Get approved faster with a card on file',
        success: 'Credit card added successfully',
        successHeader: 'Saved',
        okay: 'Okay',
        paymentInfoRequired:
            "<bold>Payment info is required to bid in most auctions.</bold><plain> This helps build trust between you and the auctioneer. You won't be charged unless you make a purchase.</plain>",
    },
    nameContactFormModal: {
        welcome: 'Welcome to LiveAuctioneers!',
        completeYourProfile:
            'Complete your profile in order to start bidding and winning. First, tell us about yourself.',
    },
    addressFormModal: {
        addShippingAddress: 'Add Shipping Address',
        aValidShippingAddress:
            'A valid shipping address is required to place a bid, and helps ensure a smooth shipping and checkout experience when you win an item.',
        next: 'Next',
        skip: 'Skip This Step',
    },
    verifyAddressModal: {
        detailedAddress: 'We found a more detailed address.',
        pleaseReview: 'Please review the updates and confirm your address below.',
    },
    bidLimitExceededModal: {
        header: 'What is a bid limit?',
    },
    completeAccount: {
        setupLink: 'Complete Account Setup',
        toSaveTimeBidding: ' to save time on bidding and winning items.',
    },
    viewWonItemsBanner: {
        congratulations: 'Congratulations! You won!',
        completeAccount: 'Complete your profile to finalize your win and ensure a smooth delivery.',
        viewWonItem: 'View Won Item',
    },
    completeAccountModal: {
        mobilePhone: 'mobile phone number',
    },
    forgotPasswordModal: {
        header: 'Please enter your email below.',
        header2: 'Check your email.',
        subHeader: "We'll send you a link to reset your password.",
    },
    loginModal: {
        error: 'Your username or password is incorrect.',
        errorAlreadyExists: "You're already registered. Log in below:",
        footer: "Don't have an account?",
        footerLink: 'Join',
        forgot: 'Forgot Password?',
        password: 'password',
        submit: 'Log In',
        username: 'EMAIL ADDRESS / USERNAME',
        errorProtect:
            'Due to a security issue detected on July 11, 2020, your password has been disabled to protect your account. Please click "Forgot Password" below to reset it. For further information, please see our',
        twoStepAuthenticationError:
            'Something went wrong, and we are working on it.{br}Try logging in later or contacting us at {supportEmail}.',
    },
    passwords: {
        changePassword: {
            confirmNewPassword: 'Confirm New Password',
            newPassword: 'New Password',
            setPassword: 'SET PASSWORD',
        },
    },
    photoRequestModal: {
        header: 'Photo Request',
        message: {
            morePhotos: 'I would like to request more photos of this item.',
            betterPhoto:
                'Please replace this photo with a higher resolution version.\r\n\r\nPhoto {imageIndex} of {imageGalleryLength}: {url}',
        },
        subject: 'Additional images request for Lot [{lotNumber}]: {title}',
        invalidMessage: 'Please enter a message less than 2,000 characters.',
        radios: {
            requestMorePhotos: 'Request more photos',
            requestBetterPhoto: 'Request better version of a specific photo',
        },
        save: 'Save',
        saved: 'Saved',
    },
    phoneVerifyModal: {
        addYourMobile: 'Add Your Mobile Number',
        aVerifiedNumber:
            'A verified mobile number builds trust with auction houses and encourages them to approve you more quickly.',
        confirmYourMobile: 'Confirm Your Mobile Number',
        verifyYourMobile: 'Verify Your Mobile Number',
        enterYourCode: 'Enter Your Verification Code',
        enterSixDigitCode: 'Enter six-digit code',
        weTextedYourCode: 'We texted your six-digit verification code to {number}. It may take a minute to arrive.',
        continue: 'Continue',
        byClickingContinue:
            'By clicking “Continue,” you agree to receive authentication codes via SMS. Message and data rates may apply.',
        didntGetIt: "Didn't get it? {link}",
        sendAnotherCode: 'Send another code',
        verifying: 'Verifying...',
        incorrectCode: 'Incorrect code. Please try again.',
    },
    setPasswordModal: {
        ariaLabel: {
            password: 'Password',
        },
        invalidPassword: 'Your password must be at least 6 characters.',
        submit: 'Join',
        username: 'Email Address',
    },
    sharedModal: {
        footer: 'Have a general question? {link}',
        footerJ52: 'Have a question about Jasper52? {link}',
        footerLink: 'Get Answers Here',
    },
    signUpModal: {
        ariaLabel: {
            emailInput: 'Enter Your Email',
        },
        acceptText: 'I accept the {tosLink} and {cookieLink}.',
        cookieLink: 'Cookie Policy',
        footer: 'Already have an account? {link}',
        footerLink: 'Log In',
        signUpAndBegin: 'Sign up and begin your treasure hunt.',
        invalidEmail: 'Please enter a valid email address.',
        enterEmail: 'Enter your email',
        submit: 'START NOW',
        termsLink: 'Terms',
        username: 'Email Address',
        worth: "What's It Worth?",
        followAuctioneer: 'Follow This Auctioneer',
        getApproved: 'Get Approved to Bid',
        registerApprove: 'Sign up to register for this auction.',
        love: 'Love This Item?',
        ready: 'Get Ready to Bid!',
        placeBid: 'Sign up to place your bid and win. ',
        question: 'Have a Question?',
        followSearch: 'Follow This Search',
        customAddress2: 'Apt/Suite/etc (optional)',
        customCompanyLabel: 'Company (optional)',
    },
    waitingOnApprovalModal: {
        title: 'Still waiting on approval?',
        reasonConsole:
            'Please allow a few minutes for your request to be approved. Auction registrations are reviewed and approved to ensure a fair auction with vetted bidders, getting you the best possible prices.',
        reason1: 'Auctioneers typically take at least a few minutes to review and approve new bidders',
        reason1NonUrgent:
            'Auction registrations are reviewed and approved to ensure a fair auction with vetted bidders, getting you the best possible prices.',
        reason2:
            'This auction house takes their time approving bidders to protect you from fraudulent bids, getting you the best price',
        subheading: 'What you can do:',
        whatyoucando1: 'Including {link}, address, and contact information typically helps get approval faster',
        whatyoucandoConsole1:
            'Auction houses have varying processes for conducting auction approvals. For the best chances of getting approved quickly, {creditCardInfo} and make sure {link} is up-to-date.',
        whatyoucando1NonUrgent:
            'Auction houses have varying processes for conducting auction approvals. For the best chances of getting approved quickly, {link}, and make sure your profile is up-to-date.',
        whatyoucando1Update: 'complete credit card information in your account profile',
        whatyoucandoConsole1Update1: 'complete your credit card information',
        whatyoucandoConsole1Update2: 'your profile',
        whatyoucando1UpdateNonUrgent: 'complete your credit card information',
        whatyoucando2: 'If you’re not approved soon, {link} to request approval',
        whatyoucandoConsole2:
            "If you're not approved soon, or if you'd like to place a live bid on an upcoming lot, contact the auctioneer at {phoneNumber}",
        whatyoucando2NonUrgent:
            'Typically auction approvals are conducted within 48 hours. If you haven’t heard from the auction house, or if the auction starts in less than 48 hours, {link} to request approval.',
        whatyoucando2Send: 'send them a message',
        whatyoucando2SendNonUrgent: 'send the auctioneer a message',
        whatyoucando3: 'Contact this auction house at {phoneNumber}',
        sellerSubject: 'Approval waiting for {fullName} on catalog "{title}"',
    },
    registrationModal: {
        titles: {
            reviewRegistration: 'Review Your Registration',
            registrationSubmitted: 'Your Registration is Submitted!',
        },
        subtitles: {
            addCardOnFile:
                'This will help us verify your eligibility to bid and will give you a better chance of getting approved to bid, but your card will not be charged.',
            completeAccountAddCard:
                "You won't be charged unless you win. This information will help you signal to auction houses that you're able to pay for the items you win.",
            completeAccountAddCardPaysafe:
                'In order to participate this auction, we ask that you please confirm your credit or debit card information below.',
            registrationPayment:
                'If you win, the auction house will follow up separately with their payment instructions.',
            updateCardOnFile:
                'Your existing card on file may be out of date. Adding a current card will help verify your eligibility to bid. Your card will not be changed now.',
            reviewRegistration: 'Get ready for the auction! Please make sure your info is correct.',
            submittedSaleNotLive:
                'You will be notified when you get approved (typically under 24 hours). Meanwhile, feel free to start bidding!',
            submittedSaleIsLive:
                'The auction has started! If you are not approved soon, please wait for a few minutes or contact the auctioneer at {sellerPhone}.',
            timedPlus: {
                submittedSaleNotLive: 'You will be notified when you get approved (typically under 24 hours).',
                submittedSaleIsLive:
                    'The auction has started! If you are not approved soon, please wait for a few minutes or contact the auctioneer at {sellerPhone}.',
                submittedSaleIsClosing:
                    "The auction is ending soon! If you don't get notified soon or you need immediate approval, please {messageHouse} directly.",
                messageHouse: 'message the auction house',
            },
        },
        notifyMe: 'Follow {sellerName}',
        cta: {
            submitRegistration: 'Submit Registration',
            updateCardOnFile: 'Save and Continue',
        },
        skip: 'Skip This Step',
        firstTimeBidder: 'First time bidder? Learn {link}',
        liveBiddingStarts: 'Live bidding starts {formattedDate}',
        conditions: {
            autopay:
                'If you win an item, your credit card will be charged 1-3 days after your invoice is issued. By clicking "Submit Registration" you agree to this auction\'s {termsOfSale}.',
            instantAutopay:
                'If you win an item, your credit card will be charged shortly after you receive an invoice. By clicking "Submit Registration" you agree to this auction\'s {termsOfSale}.',
            regularTerm: 'By clicking "Submit Registration" you agree to this auction\'s {termsOfSale}',
        },
    },
    addAddressModal: {
        title: 'Add Shipping Address',
    },
    clearCacheModal: {
        seeSafari: 'We see that you’re using Big Sur and Safari.',
        sometimesSafari:
            'Sometimes updating to a new MacOS in conjunction with a new Safari can cause browser slowness for some people.',
        cacheSafari:
            'If you are experiencing slowness, particularly in scrolling, please press [CMD] + [OPTION] + [E] to clear your browser caches.',
    },
    // pages
    termsPage: {
        title: 'Terms and Conditions: {title}',
    },
    aboutUs: {
        apply: 'apply to become an auctioneer',
        apps: "LiveAuctioneers' iPhone and Android apps",
        browse: 'browse curated auctions',
        content1:
            'LiveAuctioneers brings an international audience of millions to the heart of the bidding action in {art}, antiques, {jewelry} and {collectibles} auctions across the globe.',
        content2:
            "With fascinating objects up for bid in more than 50 countries—instantly translated to your language and currency—LiveAuctioneers is a worldwide marketplace with treasures waiting to be discovered, whether you're an avid collector or first-time visitor.",
        content3:
            "By hosting thousands of auctions in real time via the Internet, the site allows unprecedented access to remote sales, and savvy bidders can often land desired items at very desirable prices. Leave an absentee bid, or fully engage in the live-auction action—it's up to you. All bidding takes place via a secure bidder network, which keeps your maximum bids for upcoming sales private until the item is opened on the day of the sale.",
        content4:
            'LiveAuctioneers revolutionized the industry from the start. In 2002, the NYC-based company formed a marketing partnership with eBay to introduce eBay Live Auctions. This alliance of LiveAuctioneers and eBay enabled auction houses worldwide to go online with their live sales—a development that changed the auction business forever. In 2009, the debut of {apps}, with live-bidding capabilities, opened up a new mobile pipeline to bid anytime, from anywhere, with complete anonymity. Since then, LiveAuctioneers has been the first to engage bidders with live streaming video, easy online payments, personalized search alerts, and more.',
        content5:
            "Now in its second decade, LiveAuctioneers is the Web's leading auction-related site, with millions of qualified bidders. LiveAuctioneers also provides a constant stream of industry intelligence to auctioneers, collectors, museums, appraisers, consignors and more via:",
        content6:
            'The easy-to-use {auctionResults}, a vast database with more than 29 million results that offers keyword-search access to verified auction outcomes, recent to historical. The updated archive is the #1 free online resource for the trade, appraisers, collectors, designers, art institutions, estate officers, journalists, students and others on the research trail;',
        content7:
            '{auctionCentral}, the global destination for the latest auction headlines, mixing timely industry news with feature articles by experts on all things design;',
        content8:
            'The {consignmentsHub}, an easy way for interested consignors to share their property for review and evaluation with thousands of auction houses in one spot.',
        content9:
            'There are many ways to get started as a collector. You can {browse}, find {nearMe}, set up keyword search alerts, search, bid and win.',
        content10:
            'If you’re interested in selling online, {apply} or {share}. LiveAuctioneers’ award-winning {support} services are always available if you have questions.',
        description: 'Exceptional Items from Trusted\nAuction Houses',
        nearMe: 'auctions near you',
        share: 'share your consignment',
        support: 'customer support',
        title: 'About LiveAuctioneers',
    },
    accountSettings: {
        bankAccountOnFileLabel: 'Bank Account (ACH)',
        addCardOnFileButton: 'Add Credit Card',
        addNewPaymentMethod: 'Add New Payment Method',
        bankDetails: 'Add bank details for direct transfers from your bank account',
        cannotRemoveCreditCard:
            'Unfortunately your credit card cannot be removed at this time. Our records note that you either have a pending payment or recently placed an absentee bid.',
        cannotRemoveCreditCardOpenDisputes:
            'Unfortunately your credit card cannot be removed at this time. Our records note that you either have unpaid items or open disputes.',
        delete: 'Delete My Account',
        deleteAccount: 'Delete Account',
        deleteAccountWarning: "Deleting your account cannot be undone. You'll receive an email to confirm this action.",
        description: 'Change your account settings',
        bankAccountTitle: 'Easily Pay With Your Bank Account (ACH)',
        gotIt: 'got it',
        highRiskCofLabel: 'Cards on file for sensitive or regulated purchases {openModal}',
        noCardOnFile: 'No card on file found, add one today to increase your chance of being approved to bid!',
        openModal: "What's this?",
        payments: 'Payments',
        paysafeMessageOne:
            'In rare instances you may be required to re-enter your card information when registering for certain auctions. This has to do with the specific payment processor for the given sale.',
        paysafeMessageTwo:
            'There is no action you need to take at this time. To change this card, simply remove it. If required for a future auction, you will be prompted to enter your card information at that time.',
        title: 'Account Settings',
        username: 'Username',
        security: {
            header: 'Security',
            twoStepAuthentication: {
                header: 'Two-Step Authentication',
                description:
                    "This helps protect you by making it more difficult for others to log into your account. When you log in from a new device or browser, we'll email you a security code to enter on the log in screen.",
                active: 'Active',
                activate: 'Activate',
                inactive: 'Inactive',
                deactivate: 'Deactivate',

                activationModal: {
                    header: 'Activate Two-Step Authentication',
                    description:
                        'If you activate Two-Step Authentication, a security code will be sent to {email} every time you log in from a new device or browser.',
                    activate: 'Yes, activate it',
                },
                deactivationModal: {
                    header: 'Are you sure you want to deactivate Two-Step Authentication?',
                    description:
                        'Deactivating Two-Step Authentication allows log ins from unrecognized devices and browsers without requiring a security code.',
                    deactivate: 'Yes, deactivate it',
                    keepActivated: 'Keep it Active',
                },
            },
        },
    },
    verifyEmailModal: {
        title: 'Verify Your Account',
        description: 'Check your email for a 6-digit code to verify your account. Enter it below to continue bidding.',
        // input and input captions
        placeholder: 'Enter code here',
        expirationMessage: 'The code expires in 15 minutes.',
        error: 'Incorrect or expired code. Please try again.',
        // calls to action
        submit: 'Verify',
        resendCode: 'Resend Code',
        resentMessage: 'Code has been sent!',
    },
    twoStepAuthenticationModal: {
        header: 'Two-Step Authentication',
        description: {
            email: 'Please enter the 6-digit code we just sent to {emailAddress}.',
            sms: 'Please enter the 6-digit code we just sent to {phoneNumber} or your email.',

            resend: "Please wait a few minutes for the code to come through. If you don't receive it, try the following:",
        },
        yourEmail: 'your email',
        // input and input captions
        placeholder: 'Enter code here',
        expirationMessage: 'The code expires in 15 minutes.',
        error: 'Incorrect or expired code. Please try again.',
        // calls to action
        submit: 'Submit',
        tryAnotherWay: 'Try Another Way',
        // resend page main actions
        resendEmail: 'Resend code to my email',
        resendSms: 'Resend code to my phone',
        resendSmsCountdown: 'Resend code to my phone ({secondsRemaining}s)',
        resentMessage: 'Code has been sent!',
        // resend page get help
        checkOutFaqPage: 'Check our FAQ page',
    },
    newFeature: 'New!',
    paymentMethodSelection: {
        notSelectedACHLabel: 'Bank Account (ACH) On File',
        notSelectedCardLabel: 'Card On File',
        selectedACHLabel: 'Selected',
        selectedCardLabel: 'Primary Card',
    },
    plaid: {
        addBank: 'Add Bank Information',
        error: 'Sorry, there was an error adding your bank account. Please try again or {link}.',
        errorContactSupport: 'contact support',
        errorLoading: 'Unable to load plaid, please try again.',
    },
    deleteAccountModal: {
        title: 'Delete Account',
        subtitle: 'This action cannot be undone.',
        instructions:
            'To delete your account, confirm your email address and follow the instructions in the verification email to complete the process.',
        error: 'There was an error deleting your account. {link}.',
        errorTitle: 'Unable to delete account',
        errorUnpaidDispute:
            'We could not close your account because you have unpaid items or disputes. Please review your {link} for more information. ',
        support: 'Please contact support',
        cancel: 'Cancel',
        close: 'Close',
        tryAgain: 'Try Again',
        email: {
            confirmAddress: 'Confirm Your Email Address',
            invalidEmail: 'Please enter a valid email address.',
            incorrectEmail: 'This email address is not associated with your account. Please try again.',
            send: 'Send Email',
            error: {
                title: 'Something went wrong',
                subtitle:
                    'It looks like we had trouble sending the verification email to confirm your account deletion. Please try again later. If the problem persists, contact our support team at {link} for assistance.',
            },
            success: {
                title: 'Verification Sent',
                subtitle:
                    "We've sent you a verification email. Check your inbox (and spam folder, just in case) to confirm your account deletion.",
                note: 'Please note: The confirmation link will expire in 24 hours.',
            },
            retryError: {
                title: 'Still having trouble?',
                subtitle:
                    'We’re sorry, but it looks like we’re still unable to send the verification email for your account deletion. This might be a temporary issue on our end.',
                note: 'Please contact our support team at {link}, and we’ll assist you as quickly as possible. We appreciate your patience and understanding.',
            },
        },
    },
    deleteAccountConfirmation: {
        title: 'Delete Account',
        description: 'Delete your Account',
        success: {
            title: 'Your account has been deleted.',
            subtitle:
                "Your account has been permanently removed. We're sorry to see you go. If you have any questions or need further assistance, feel free to {link}.",
            cta: 'Go to Homepage',
        },
        expired: {
            title: 'Oops, it looks like the verification link has expired.',
            subtitle:
                "For security reasons, the link expires after 24 hours. But don't worry, you can request a new verification email to continue your account deletion process.",
        },
        error: {
            title: 'Oops, something went wrong.',
            subtitle: 'We were unable to delete your account. Please try again or {link} for assistance.',
        },
        support: 'contact support',
        requestAnotherEmail: 'Request Another Email',
    },
    getHelpModal: {
        headers: {
            [TitleKeys.HowCanWeHelp]: 'How Can We Help You?',
            [TitleKeys.SendMessage]: 'Send Message',
            [TitleKeys.Policy]: 'Auction Policy',
        },
        auctionPolicy: 'Auction Policy',
        shippingCountries: '50 Countries',
        helpCenter: 'Visit Help Center',
        itemCount: '{count} {count, plural, one {item} other {items}}',
        moreQuestions: 'Have more questions? {helpCenterLink}',
        messageHouse: 'Message Auction House',
        fileClaim: 'File a Claim',
        messageSupport: 'Message Support',
        messenger: {
            subjectPlaceholder: 'Select a topic you need help with',
            messageSent: {
                house: 'You will receive a notification when {sellerName} responds to your message.',
                support:
                    'Your message has been sent to LiveAuctioneers Support. You should receive a response in email in 24 hours.',
            },
            error: {
                messageError: 'Please enter a message',
                subjectError: 'Please select a topic',
            },
        },
        infoMessages: {
            [InfoMessageKeys.ShippingHelp]: 'Need more help with shipping?',
            [InfoMessageKeys.LiveShippingQuestions]:
                "If you have any shipping questions, don't hesitate to contact LiveAuctioneers.",
            [InfoMessageKeys.HouseShippingQuestions]:
                'If you have any shipping questions, please contact the auction house directly.',
            [InfoMessageKeys.NeedHelp]: 'Still need help?',
            [InfoMessageKeys.RegistrationQuestions]:
                'For any questions about your registration, contact the auction house directly.',
            [InfoMessageKeys.BidLimitQuestions]:
                'If you’d like to increase your bid limit, please contact the auction house directly.',
            [InfoMessageKeys.PickupAndSelfArrangedShippingQuestions]:
                'If you have any questions about pickup and self-arranged shipping, please get in touch with the auction house directly.',
        },
        topics: {
            itemPage: {
                [ItemPageTopicKeys.BidLimit]: {
                    headline: 'Bid Limit',
                    content: {
                        [MessageKeys.Primary]:
                            'Auction houses may set a limit on the total value of items you can win in a catalog. These bid limits are typically applied to bidders who have little or no bidding history with the auction house. If the auction house gave you a bid limit, you can find it on the auction page and the item page.',
                    },
                },
                [ItemPageTopicKeys.PlaceABid]: {
                    headline: 'Place A Bid',
                    content: {
                        [MessageKeys.Primary]:
                            "When you submit a bid, you can enter the maximum amount you're willing to pay for the item, and LiveAuctioneers will bid for you incrementally to keep you in the lead. The auction house won’t see your maximum bid amount.",
                        [MessageKeys.Secondary]:
                            'Remember, you need to register for the auction and get approved by the auction house. In most cases, the system will automatically submit the registration request for you when you submit your first bid in an auction. You can submit more bids while waiting for approval, but they won’t be processed until the approval is granted.',
                    },
                },
                [ItemPageTopicKeys.RegisterForAnAuction]: {
                    headline: 'Register For An Auction',
                    content: {
                        [MessageKeys.Primary]:
                            'To participate in an auction, you need to register and get approval from the auction house. In most cases, the system will automatically submit the registration request for you when you submit your first bid in an auction. You may also register without submitting a bid, simply click "Register for Auction" on the item or auction page.',
                        [MessageKeys.Secondary]:
                            'You will receive an email notification once your registration is approved or declined. In most cases, you can submit bids while waiting for approval, but your bids won’t be processed until approval is granted. Most auction houses approve bidders immediately or within 24 hours, but some may have more stringent requirements and will notify you if additional information is needed.',
                    },
                },
                [ItemPageTopicKeys.WinningOrLosingALot]: {
                    headline: 'Winning Or Losing A Lot',
                    content: {
                        [MessageKeys.Primary]:
                            'If you win a lot, you will receive an email notification and you can see your order on the {linkToWonItems}. If it’s not there, it could be because: {bulletList}',
                        listMessages: {
                            [MessageListKeys.A]:
                                'The lot hasn’t closed yet and the auctioneer is still accepting bids.',
                            [MessageListKeys.B]:
                                'The lot has closed and you didn’t win because you were outbid by buyers on either LiveAuctioneers or other platforms.',
                            [MessageListKeys.C]:
                                'The lot did not meet the reserve price and was passed. Reserve prices are set by the auction house; they are the minimum prices at which items will sell. Reserve prices are not revealed by the LiveAuctioneers system.',
                        },
                    },
                },
                [ItemPageTopicKeys.RetractABid]: {
                    headline: 'Retract A Bid',
                    content: {
                        [MessageKeys.Primary]:
                            'Please note that absentee bids can be retracted only up to 2 hours before the auction start time. No other bids can be retracted. Only the bidder can retract the bid.',
                        [MessageKeys.Secondary]:
                            "To retract your bid, go to the {linkToMyBids} page. Hover over the item you wish to retract your bid on, select the three dots, and then select the 'Retract Bid'. Follow the steps to complete the retraction.",
                    },
                },
                [ItemPageTopicKeys.ItemPriceAndFees]: {
                    headline: 'Item Price & Fees',
                    content: {
                        [MessageKeys.Primary]: 'Winning bidders are responsible for: {bulletList}',
                        listMessages: {
                            [MessageListKeys.A]: 'Final item price',
                            [MessageListKeys.B]: "Buyer's premium - an extra fee set by the auction house",
                            [MessageListKeys.C]:
                                'Tax (where applicable) - determined by shipping destination or pickup location',
                            [MessageListKeys.D]:
                                'Shipping (where applicable) - you may also pick up or arrange your own shipping depending on the auction policy',
                            [MessageListKeys.E]:
                                'Online payment fee (where applicable) - a non-refundable charge for processing your online payment',
                        },
                    },
                },
                [ItemPageTopicKeys.AddPaymentMethod]: {
                    headline: 'Add A Payment Method',
                    content: {
                        [MessageKeys.Primary]:
                            'To add a payment method, go to {linkToAccountSettings}, then scroll down to Payments and click Add Credit Card. ',
                    },
                },
                [ItemPageTopicKeys.TaxExemption]: {
                    headline: 'Tax Exemption',
                    content: {
                        [ContentKeys.LivePayments]: {
                            [MessageKeys.Primary]:
                                'Tax exemption is determined by either your shipping address or the location for item pickup. To submit your tax exemption certificates: {numberedList}',
                            [MessageKeys.Secondary]:
                                'If you need assistance with uploading the tax certificate, feel free to email it to {supportEmailLink}.',
                            listMessages: {
                                [MessageListKeys.A]: 'Navigate to {linkToAccountSettings}',
                                [MessageListKeys.B]:
                                    'Under the My Profile section, make sure “I’m tax exempted” is checked.',
                                [MessageListKeys.C]:
                                    'Go to the {linkToTaxExemption} page and upload your certificate. ',
                            },
                        },
                        [ContentKeys.NonLivePayments]: {
                            [MessageKeys.Primary]:
                                'Tax exemption eligibility is determined by the state of your shipping address or the location from which you plan to pick up your item(s). To qualify for tax exemption, please forward the certificate to the auction house at {auctionHouseEmail} for exemption processing.',
                        },
                    },
                },
                [ItemPageTopicKeys.ShippingOption]: {
                    headline: {
                        [ContentKeys.LiveShipping]: 'Shipping Option',
                        [ContentKeys.NonLiveShipping]: 'Shipping & Pickup Options',
                    },
                    content: {
                        [ContentKeys.LiveShipping]: {
                            [MessageKeys.Primary]:
                                'Shipping via LiveAuctioneers is available for this item. LiveAuctioneers works with top-rated partners to ship across the U.S. and more than {availableCountries}. Our partners will pick up, package, and ship your item professionally.',
                        },
                        [ContentKeys.NonLiveShipping]: {
                            [MessageKeys.Primary]:
                                'Each auction house has their own policies for shipping and pickup. Please review the {auctionPolicy} for more details.',
                        },
                    },
                },
                [ItemPageTopicKeys.PickupAndSelfArranged]: {
                    headline: 'Pickup & Self-Arranged Shipping',
                    content: {
                        [MessageKeys.Primary]:
                            'If you prefer to pickup or arrange your own shipping, please review the {auctionPolicy} first as each auction house has their own policies related to pickup and item releases to third-party shippers. In most cases, you will find your shipping and pickup options on your invoice and can select the preferred method at Checkout. ',
                    },
                },
                [ItemPageTopicKeys.ShippingFee]: {
                    headline: 'Shipping Fee',
                    content: {
                        [MessageKeys.Primary]:
                            "Shipping quotes are based on the item's dimensions, weight, fragility, value, and shipping distance. It includes full carrier services such as: {bulletList}",
                        [MessageKeys.Secondary]:
                            'The shipping quote may change based on the final item price, as higher item values require higher insurance. If a shipping quote is not displayed on the item page, you may request a quote by contacting LiveAuctioneers. Where applicable, the shipping fee will be taxed based on your shipping destination.',
                        listMessages: {
                            [MessageListKeys.A]: 'Item pickup from the auction house',
                            [MessageListKeys.B]: 'Packing materials',
                            [MessageListKeys.C]: 'Full insurance coverage',
                            [MessageListKeys.D]: 'Tracking notifications',
                            [MessageListKeys.E]: 'Professional handling',
                        },
                    },
                },
                [ItemPageTopicKeys.TrackShippingAndDelivery]: {
                    headline: 'Track Shipping & Delivery Time',
                    content: {
                        [MessageKeys.Primary]:
                            'For orders shipped with LiveAuctioneers, you will see the status on the {linkToWonItems} page and receive tracking email notifications as it goes through the shipping process. The tracking number and estimated arrival time will be available if provided by the carrier.',
                        [MessageKeys.Secondary]:
                            'The turnaround time depends on many factors and is usually longer for large items, late bookings, holidays, and international shipments. Under normal circumstances, it takes 2-3 weeks on average for an item(s) to be picked up, packaged, shipped, and delivered to you.',
                    },
                },
                [ItemPageTopicKeys.BrokenOrMissing]: {
                    headline: 'Broken, Missing, or Wrong Item',
                    content: {
                        [ContentKeys.LiveShipping]: {
                            [MessageKeys.Primary]:
                                'All items that are shipped with LiveAuctioneers receive 100% insurance coverage. In the unfortunate event that an item arrives damaged, please {fileClaimLink} and our team will determine the appropriate course of action. Claims usually take 1-2 weeks to be processed.',
                        },
                        [ContentKeys.NonLiveShipping]: {
                            [MessageKeys.Primary]:
                                'If you received broken, incorrect, or missing item(s), please contact the auction house directly. If you need further help, reach out to LiveAuctioneers at {supportEmailLink} to mediate with the auction house.',
                        },
                    },
                },
                [ItemPageTopicKeys.ItemCondition]: {
                    headline: 'Item Condition and Authenticity',
                    content: {
                        [MessageKeys.Primary]:
                            "For any questions about the item's condition or authenticity, please contact the auction house directly. If you need further help, reach out to LiveAuctioneers at {supportEmailLink} to mediate with the auction house.",
                    },
                },
                [ItemPageTopicKeys.RefundForDoubleCharge]: {
                    headline: 'Refund For Double Charge',
                    content: {
                        [MessageKeys.Primary]:
                            'If you have been auto-charged after paying the auction house directly and need a refund for the duplicate payment, please email {supportEmailLink} with the invoice number as well as the payment confirmation email for payment sent to the seller.',
                        [MessageKeys.Secondary]:
                            'To avoid duplicate payments in the future, make sure you contact the auction house before the scheduled autopay date as they can deactivate autopay if payment is accepted on their end.',
                    },
                },
                [ItemPageTopicKeys.CancellationRequest]: {
                    headline: 'Item Cancellation Request',
                    content: {
                        [MessageKeys.Primary]:
                            'If you received an “Item cancellation request” email, it does unfortunately mean that the auction house will not be able to fulfill your order for some reasons. ',
                        [MessageKeys.Secondary]:
                            "LiveAuctioneers understands your frustration. We closely monitor such cancellations and discourage auction houses from doing so to prevent negative experiences for bidders. But don't worry—there are still plenty of similar items waiting for you to discover!",
                    },
                },
            },
            wonItemsPage: {
                [WonItemsPageTopicKeys.HowToPay]: {
                    headline: 'How To Pay',
                    content: {
                        [ContentKeys.LivePayments]: {
                            [MessageKeys.Primary]:
                                'LiveAuctioneers accepts multiple payment options, including major debit and credit cards, eChecks, and payment installments through ZIP. Payments can either be made manually via the invoice you receive, or you can allow them to be automatically charged 24-72 hours (as advised on the invoice) after getting the invoice.',
                            [MessageKeys.Secondary]: 'Should you have any further questions, please contact us.',
                        },
                        [ContentKeys.NonLivePayments]: {
                            [MessageKeys.Primary]:
                                'An auction house usually accepts multiple forms of payments which can include but is not limited to credit/debit cards, bank transfers (ACH), Check, Cash, and Wire Transfer. Please review the auction houses Auction Policy for the preferred payment methods.',
                            [MessageKeys.Secondary]:
                                'Should you have any further questions regarding other preferred payment methods, please reach out to the seller.',
                        },
                    },
                },
                [WonItemsPageTopicKeys.ChangePaymentMethod]: {
                    headline: 'Change Payment Method',
                    content: {
                        [MessageKeys.Primary]:
                            'If you have not yet paid for your item(s) you can change you preferred payment method either by going to Account Settings and then Card on File or changing the payment method during checkout.',
                        [MessageKeys.Secondary]:
                            'If you have already paid for your item(s) and wish to use a different payment method, we will need to process a refund and then charge the new card or bank account. If you would like for us to process a refund for this reason, please email {email} with the invoice number and confirmation of the seller agreeing to refund the invoice and have it paid with a different card or bank account. ',
                    },
                },
                [WonItemsPageTopicKeys.TaxExemption]: {
                    headline: 'Tax Exemption',
                    content: {
                        [ContentKeys.LivePayments]: {
                            [MessageKeys.Primary]:
                                'Tax exemption is determined by either your shipping address or the location for item pickup. To submit valid tax exemption certificates, please navigate to Account Settings, followed by Tax Exemption. If you need assistance with uploading the tax certificate, feel free to email it to {email}.',
                        },
                        [ContentKeys.NonLivePayments]: {
                            [MessageKeys.Primary]:
                                'Tax exemption eligibility is determined by the state of your shipping address or the location from which you plan to pick up your item(s). To qualify for tax exemption, please forward the certificate to the Auction House for exemption processing.',
                        },
                    },
                },
                [WonItemsPageTopicKeys.RefundForDoubleCharge]: {
                    headline: 'Refund For Double Charge',
                    content: {
                        [MessageKeys.Primary]:
                            'If you have been auto charged after paying the auction house directly and need a refund for the duplicate payment, please email {email} with the invoice number as well as the payment confirmation for payment sent to the seller. ',
                        [MessageKeys.Secondary]:
                            'To avoid duplicate payments in the future, make sure you are contacting the seller before the scheduled autopay date as they can deactivate autopay if payment is accepted on their end.',
                    },
                },
                [WonItemsPageTopicKeys.ShippingAndPickupOptions]: {
                    headline: 'Shipping & Pickup Options',
                    content: {
                        [MessageKeys.Primary]:
                            'Each Auction House has a particular process to arrange the shipment of items. In most cases, you will find your shipping and pickup option on your invoice, from there you can then select your preferred shipping or pick up method when you pay for your item(s).',
                        [MessageKeys.Secondary]:
                            'If you have any questions or if the shipping and pickup options are not present on your invoice, please contact the auction house directly.',
                    },
                },
                [WonItemsPageTopicKeys.ShipYourOrder]: {
                    headline: 'Ship Your Order',
                    content: {
                        [MessageKeys.Primary]:
                            'LiveAuctioneers works with vetted and professional shipping services to provide hassle-free delivery for one-of-a-kind items in the auction. If you have not yet paid for your item(s), you can see the Ship with LiveAuctioneers option at Checkout. If the invoice is paid, click “Add Shipping” on the Won Items page to select this method.',
                        [MessageKeys.Secondary]:
                            'Once the invoice is paid in full, our partners will pick up, package, and ship your item to the address provided at Checkout.',
                        [MessageKeys.Tertiary]:
                            'If you have any questions or if the shipping option is not present on your invoice, please contact us.',
                    },
                },
                [WonItemsPageTopicKeys.PickupAndSelfArranged]: {
                    headline: 'Pickup & Self-Arranged',
                    content: {
                        [MessageKeys.Primary]:
                            'Prefer to arrange shipping yourself? Each Auction House has a particular process for arranging the pickup or release of items to third-party shippers. In most cases, you will find your shipping and pickup options on your invoice and can select the preferred method at Checkout.',
                        [MessageKeys.Secondary]:
                            'If you have any questions, want to schedule pickup, or arrange your shipping, or if options are not present on your invoice, please get in touch with the auction house directly.',
                    },
                },
                [WonItemsPageTopicKeys.TrackShippingAndDelivery]: {
                    headline: 'Track Shipping & Delivery Time',
                    content: {
                        [MessageKeys.Primary]:
                            'For orders shipped with us, you will see the status on the “Won Items” page and receive tracking email notifications as it goes through the shipping process. The tracking number and estimated arrival time will be available if provided by the carrier. ',
                        [MessageKeys.Secondary]:
                            'The turnaround time depends on many factors and is usually longer for large items, late bookings,  holidays, and international shipments. Under normal circumstances, it takes 2-3 weeks on average for an item(s) to be picked up, packaged, shipped, and delivered to you. ',
                        [MessageKeys.Tertiary]:
                            'Please contact us for order details if it is not in transit, and the status has not changed for over five business days.',
                    },
                },
                [WonItemsPageTopicKeys.Inauthentic]: {
                    headline: 'Item Condition',
                    content: {
                        [MessageKeys.Primary]:
                            'If the item(s) you received is not as described or you believe it’s inauthentic, please email us at {email} with the specific invoice, pictures of lots and Certificate of Authenticity to further investigate and assist.',
                    },
                },
                [WonItemsPageTopicKeys.CancellationRequest]: {
                    headline: 'Item Cancellation Request',
                    content: {
                        [MessageKeys.Primary]:
                            'If you received the “Item cancellation request” email, it does unfortunately mean that the auction house will not be able to fulfill your order. While we don’t know the exact reason why they elected to cancel the sale, we discourage auction houses from doing so and monitor this activity.',
                        [MessageKeys.Secondary]:
                            'We understand how frustrating this must be, especially because you were notified you were the winner. But don’t worry, there are plenty of similar items on LiveAuctioneers! We encourage you to set up search alerts to help you find your next treasure.',
                    },
                },
                [WonItemsPageTopicKeys.BrokenOrMissing]: {
                    headline: 'Broken, Missing, or Wrong Item',
                    content: {
                        [ContentKeys.LiveShipping]: {
                            [MessageKeys.Primary]:
                                'All items that are shipped with LiveAuctioneers receive 100% insurance coverage. In the unfortunate event that an item arrives damaged, please {fileClaimLink} and our team will determine the appropriate course of action. Claims usually take 1-2 weeks to be processed.',
                        },
                        [ContentKeys.NonLiveShipping]: {
                            [MessageKeys.Primary]:
                                'If you received broken, incorrect or missing item(s), kindly email {email} with the invoice number as well as any photos you might have. With this, we will be able to assist further and mediate a solution with the seller.',
                        },
                    },
                },
            },
        },
    },
    notificationsPage: {
        toggleBrowserNotifcations: 'Toggle browser notification for {name}',
        toggleEmailNotifcations: 'Toggle email notification for {name}',
        toggleSMSNotifcations: 'Toggle sms notification for {name}',
        toggleAllNotifcations: 'Toggle all {type} notification for {name}',
        title: 'Emails & Notifications',
        description: 'Update email and notification settings',
        missingNumber: 'Add a mobile phone number',
        unsubscribeAll: 'Turn off all notifications',
        disabled: 'Modify your browser preferences to proceed with enabling browser notifications.',
        section: {
            newsletters: 'Marketing Updates ({ count })',
            alerts: 'Auction Alerts ({ count })',
            searches: 'Followed Searches ({ count })',
            auctioneers: 'Followed Auctioneers ({ count })',
        },
        table: {
            header: {
                browserAlert: 'Browser',
                emailAlert: 'Email',
                searchTerm: 'Search Term',
                smsAlert: 'SMS',
                type: 'Alert Type',
                lastNewResult: 'Last New Result',
            },
            descriptions: {
                '1': 'Receive an email notifying you if someone else bids on an item on which you have already placed a bid.',
                '2': 'Notifies you when an item you bid on or saved is almost up for auction.',
                '3': "Notifies you when you're approved or declined for auctions you registered for.",
                '4': "Get a reminder when items you've bid on are going live in the next couple days.",
                '5': 'A digest of newly listed auctions from auctioneers you follow.',
                '6': 'Receive an email asking you to rate and review your buying experience with auctioneers.',
                '7': 'Reminders to pay open invoices.',
                '8': "Receive a round-up of lots you recently browsed in auctions you haven't registered for.",
                '9': 'Get a confirmation email when you register to bid in an auction.',
                '10': "Notifies you when you're approved to bid in an auction you registered for.",
                '11': 'Notifies you when an auction house declines your request to bid in an auction.',
            },
            frequency: {
                '1': 'Real-Time',
                '2': 'Real-Time',
                '3': 'Real-Time',
                '4': 'Daily (when you have bids)',
                '5': 'Every other day (if new auctions listed)',
                '6': 'Three weeks after winning',
                '7': 'Two days after winning',
                '8': 'Weekly',
                '9': 'Real-Time',
                '10': 'Real-Time',
                '11': 'Real-Time',
            },
            managePreferencesRow: {
                title: 'Category Preferences',
                description:
                    'Receive a weekly digest about exclusive and exceptional auctions and items in your favorite categories.',
                frequency: 'Weekly',
                link: 'Manage',
            },
        },
    },
    addCreditCard: {
        description: 'Add a credit card',
        title: 'Add credit card',
    },
    passwordReset: {
        description: 'Reset your password',
        title: 'Reset Your Password',
        error: 'There was an error resetting your password.',
        errorIncorrectCode: 'The password reset code is incorrect.',
        errorResetCodeExpired: 'The password reset code has expired.',
        errorTryAgain: 'Click here to try again.',

        success: {
            title: 'Your password has been reset',
            description: 'Use your new password to log in',
            logIn: 'Log in',
        },
    },

    // Head Data
    get_in_on_the_auction:
        'Win one-of-a-kind items from auctions around the world. Awarded Excellent (4.5 stars) on Trustpilot by thousands of bidders.',
    seller_review_meta:
        "Read Seller Reviews of LiveAuctioneers. World's Largest Art, Antiques & Collectibles Online Marketplace. 280,000+ items for online auction from 5,000+ auctioneers.",
    default_title: 'LiveAuctioneers: Online Auctions for Arts, Antiques & Collectibles',

    // Header
    starting_bid: 'Starting Price',
    auctioneer: 'Auctioneer',
    auction: 'Auction',
    date: 'Date',

    // Crown
    price_results: 'Price Results',
    news: 'News',
    help: 'Help',
    consign: 'Consign',

    // Outdated Browser Modal
    did_you_know_that_your_web_browser:
        "Did you know that your web browser is a little outdated? You're using {formalName} version {majorVersion}.",
    browsers_for_best_experience:
        'For the best LiveAuctioneers experience, please upgrade to the latest version of {chrome}, {firefox}, {safari}, or {edge}.',

    // Search
    my_bids: 'My Bids',
    my_auctions: 'My Auctions',
    won_items: 'Won Items',
    messages: 'Messages',
    followed_auctioneers: 'Followed Auctioneers',
    followed_searches: 'Followed Searches',
    account_settings: 'Account Settings',
    auction_price_results: 'Auction Price Results',
    deleted_item_1: 'is no longer available.',
    deleted_item_2: 'Create a search alert',
    deleted_item_3: 'or find similar items below.',
    searchPage: {
        topRated: 'Top Rated House',
        clearAll: 'Clear All',
        mobileHeader: {
            title: 'Filter & Sort',
        },
        '10kPlusResults': '10,000+ Results',
        resultsCount: '{formattedNumber} {totalFound, plural, one {Result} other {Results}}',
        noResults: {
            title: 'No results for “{searchTerm}”',
            body: 'Sorry, we can’t find any upcoming items or past listings that match “{searchTerm}”.',
        },
        noFilterResults: {
            title: 'Sorry, we couldn’t find anything upcoming for sale that matches what you are looking for.\nTry clearing your filters or starting a new search.',
            viewPriceResultsText: 'Or you can explore the {totalSoldItems} price results that match “{searchTerm}”.',
            viewPriceResultsLink: 'View price results',
        },
        searchResults: {
            title: 'Results for “{searchTerm}”',
            body: '({totalFoundRounded} upcoming {totalFoundRounded, plural, one {item} other {items}})',
            soldItemsBody: '({totalSoldItems} price results)',
        },
        getNotified: {
            title: 'Get notified when new items arrive',
            body: 'While we don’t have what you’re looking for at this time, follow this search and we’ll alert you as soon as more arrive.',
        },
        facets: {
            itemLocation: 'Item Location',
            anywhere: 'Anywhere',
            shipping: 'Shipping & Delivery',
            filterActionHouses: 'Filter Auction Houses',
            noResultsFound: 'No results found',
            applyFilter: 'Apply',
            materialsAndTechniques: 'Materials & Techniques',
            allFilters: 'All Filters',
            viewResults: 'View ({totalFoundText})',
            priceRange: 'Price Estimate',
        },
        searchResultsAd: {
            exploreAuction: 'Explore the Auction {icon}',
            sponsoredBy: 'Sponsored by {houseLink}',
        },
    },

    categoryCatalogs: {
        title: ' Auctions',
    },
    trustPilotWidget: {
        title: 'Shop with confidence on LiveAuctioneers.',
    },

    favorites: {
        title: 'Favorites',
        favorite_items: 'Favorite Items',
    },
    forYou: {
        title: 'For You',
        items: 'New Items',
        itemsTab: 'For You - New Items',
        searches: 'My Searches',
        searchesTab: 'For You - My Searches',
        auctioneers: 'My Auctioneers',
        auctioneersTab: 'For You - My Auctioneers',
        followMoreCategories: 'Follow More Categories',
    },
    manageNotifications: 'Manage Notifications',

    // My items page
    myItems: {
        title: 'My Items',
        favorites: 'Favorites',
        bids: 'My Bids',
    },

    // Navigation
    navigation: {
        art: 'Art',
        categories: 'Categories',
        jewelry: 'Jewelry',
        asian_antiques: 'Asian Antiques',
        furniture: 'Furniture',
        collectibles: 'Collectibles',
        home_and_decor: 'Home & Décor',
        fashion: 'Fashion',
        coins: 'Coins',
        explore_categories: 'Explore Categories',
    },

    walkthroughs: {
        onboarding,
        skipAllTips: 'Skip All Tips',
        gotIt: 'Got it',
    },

    // Home
    home: 'Home',
    online_auctions: 'Online Auctions',
    exceptional_items_from_trusted_auction_houses: 'Exceptional Items from Trusted\nAuction Houses',
    consignments_description:
        "LiveAuctioneers' mission is to connect buyers with sellers of quality goods. If you own an item(s) you would like to consign, we can help make that key connection to our more than 5,000+ auctioneers.",
    browse_by_category: 'Staff Curated Collections',
    featured_auctions: 'Featured Auctions',
    trending_items: 'Trending Items',
    online_auctions_count: '{liveCount} Online Auctions',
    explore: 'Explore',
    live_auction: 'Bid Now',
    live: 'Live',
    paused: 'Paused',
    see_all: 'See All',
    auctioneer_directory: 'Auctioneer Directory',
    auction_previews: 'Auction Previews',
    auction_results: 'Auction Results',
    be_informed: 'Be Informed',
    top_news: 'Top News',
    auctions_you_may_like: 'Auctions You May Like',

    // Live Items
    bid_live: 'Bid Live',
    bids: 'Bids',
    bidCount: '({formattedBid} {numberOfBids, plural, one {bid} other {bids}})',
    select_maximum_bid: 'Your Maximum Bid:',
    selectZip: 'or 4 payments starting at ${cost} with {image}',
    select_create_bid_option: 'Bid {amount}',
    select_create_bid_placeholder: 'Enter Bid',
    select_create_bid_timed_plus_placeholder: 'Select',
    increase_your_maximum_bid: 'Increase Your Maximum Bid ({amount}):',
    place_bid: 'Place Bid',
    secure_bidding: 'Secure',
    short_estimate_value: 'Est. {number}',
    short_estimate_values: 'Est. {low}-{high}',
    short_estimate_values_print: 'Est. {low} - {high}',
    register_for_auction: 'Register for Auction',
    registration_declined: 'Registration Declined',
    account_suspended: 'Account Suspended',
    help_center: 'Help Center',
    auctioneer_has_declined_registration: 'The auctioneer has declined your registration.',
    registration_pending: 'Registration Pending',
    bid_registration_pending: 'Bid Pending',
    registration_approved: 'Registration Approved',
    unregistered_message:
        "You'll have to register before you can place bids in this auction. This auction has already started, so you might want to hurry!",
    youAreTheLeadingBidder: 'You are the Leading Bidder!',
    increase_your_chances: 'Increase your chances of winning by bidding higher.',
    increase_bid: 'Increase bid',
    why_cant_i_retract_bid_popover_text: 'Bids may not be retracted after an auction has started.',
    item_lot_number: 'Lot {lotNumber}',
    request_online_bidding: 'Request online auction',
    requestOnlineAuction: 'Request Online Auction',
    onlineAuctionRequested: 'Online Auction Requested',
    register_partners_site: 'Register on Partner Site',
    visit_partners_site: 'Visit Partner Site',
    register_partners_site_warning: 'Clicking this link will redirect you to our partner’s website.',
    no_bid_reason:
        "Online bidding isn't available for this catalog. To request online bidding {link} and you’ll be notified if bidding is made available.",
    request_submitted: 'Request submitted',
    request_submitted_long: "We'll send you an email if the catalog begins accepting bids.",

    toastMessage: 'This item is accepting live bids! Place your live bid below to win.',

    // Ended Items
    ask_auctioneer: 'Ask Auctioneer',
    sell_similar_item: 'Sell a Similar Item',
    show_bid_history: 'Show Remaining Bid History',
    bidder: 'Bidder',
    lot_unsold_pending: 'Lot Pending',
    starting_bid_lot_passed: 'Starting Bid {amount}',
    view_details: 'view details',

    price: 'Price',
    bid_increment: 'Bid Increment',

    // Discover Items
    discover_similar_items: 'Discover Similar Items',
    discoverPageSuggestions: {
        title: 'Related Searches',
    },
    categoryRelatedSearches: {
        title: 'Related Searches',
    },
    item_creator_price_guide: '{creatorName} Price Guide',
    featured: 'Featured',

    similarSoldItemCarousel: {
        title: 'Similar Sale History',
        titleWithCreator: '{creatorName} Sale History',
        categoryLink: 'View More Items in {categoryName}',
        priceGuideLink: 'View Price Results for {creatorName}',
        categoryButton: 'View More Items',
        priceGuideButton: 'View Price Results',
        badge: 'Sold',
    },

    // Countdown
    day: 'd',
    days: 'DAY',
    hr: 'h', // abrev for Hour
    hrs: 'HR',
    min: 'm',
    mins: 'MIN',
    sec: 's',
    secs: 'SEC',
    left: '{formattedDate} Left',
    startsIn: 'Starts {formattedDate}',

    // Item Gallery
    item_gallery: {
        back: 'Back',
        back_to_gallery: 'Back to Gallery',
    },

    // Item Description:
    bid_increments: 'Bid Increments',
    condition: 'Condition',
    dimensions: 'Dimensions',
    weight: 'Weight',
    item_page_lot_number: 'Lot {number}',
    terms: 'Terms',
    shipping: 'Shipping',
    shipping_and_payment: 'Shipping & Payment',

    // Social Icons
    socialIcons: {
        clipboard: 'Copy to clipboard',
        email: 'Share to email',
        pinterest: 'Share to Pinterest',
        x: 'Share to X',
        facebook: 'Share to Facebook',
        nativeShare: 'I thought you might like this item on LiveAuctioneers.',
    },

    // Shipping with ARTA
    arta_1: 'LiveAuctioneers has partnered with ARTA to offer an automated shipping solution for auction purchases. This shipping rate is based on the ZIP/postal code provided and includes packing, shipping, and insurance.*',
    arta_2: 'Small items are delivered by parcel carriers (UPS, DHL) and large items receive quotes for white glove delivery.',
    arta_3: 'A single shipping rate for all lots won in this auction will be available to book at checkout. Tracking information will be provided by ARTA, with expected delivery within two weeks.',
    arta_4: 'For any shipping-related questions, please email { email } or see ARTA { privacy }, { standards }, and { terms }.',
    arta_4_email: 'hello@shipARTA.com',
    arta_4_privacy: 'Privacy',
    arta_4_standards: 'Standards',
    arta_4_terms: 'Terms',
    arta_5: '*International shipments do not include duties or taxes.',

    // Similar Items
    bid_most_popular_items: 'Other Popular Items',

    // Popular Items
    see_whats_popular: 'See what’s popular on LiveAuctioneers',

    // Item Card
    bid: 'Bid',
    sold_for: 'Sold for {amount}',
    sold: 'Sold',
    lotWithdrawn: 'Lot Withdrawn',

    // Saved Items
    empty_saved_items_message_part_1:
        'No upcoming favorite items yet.  To see favorite items that have already ended, {here} or visit the Past chip above.',
    empty_saved_items_message_past_part_1: 'No past favorite items',
    empty_saved_here: 'Click here',
    empty_saved_items_message_part_2:
        "As you search, tap the {heart} icon to save your favorite items and they'll be saved here. You'll also be alerted as favorite items come up at auction. {link}",
    start_your_search_now: 'Start your search now.',

    // Shared
    // AuctionInfo
    followers: '{count} Followers',
    top: 'TOP RATED',
    top_message:
        'This auctioneer consistently excels in accuracy, communication, ease of payment, and shipping quality.',
    top_rated_header: 'Top Rated Auctioneer',
    top_learn_more: 'Learn More',

    // Modals
    // HEALTHCHECK
    healthcheck: {
        refresh: 'refresh',
        sectionTitle: {
            server: 'Server',
            client: 'Client',
            deploymentData: 'Deployment Data',
            experiments: {
                title: 'Experiments',
                disabledExpTitle: "Here are some experiments that exist on the page, but aren't currently enabled",
                allExpTitle: 'Here are all experiments',
            },
        },
        tableHeaders: {
            experiments: {
                name: 'Experiment Name',
                id: 'Experiment ID',
                variant: 'Experiment Variant',
                currentVariant: 'Currently Used Variant',
                unset: 'Unset?',
            },
            apiStatsTable: {
                number: '#',
                method: 'Method',
                status: 'Status',
                apiUrl: 'API URL',
                time: 'Time',
                waterfall: 'Waterfall',
            },
        },
    },

    // Install Flash
    optional_parenthesis: '(optional)',
    countryCapitalized: 'Country',
    enter_a_valid: 'Enter a valid {token}.',

    // Sign up
    we_experienced_a_server_error: "Sorry, we've experienced a server error.",
    show: 'show',
    hide: 'hide',

    // Login
    password: 'Password',
    submit: 'Submit',
    incorrect_password: 'Incorrect password',

    // Download Mobile Modals
    bid_live_using_mobile_apps: 'Bid LIVE using our mobile apps',
    enter_the_live_auction_on_our_app: 'Enter the live auction on our free {os} app',
    get_the_free_app_now: 'Get the free app now',
    use_our_apps_to_bid:
        'Use our apps to bid on thousands of antique, fine art, and collectible items from around the world. Preview items in upcoming auctions, place bids in advance, watch and bid on live auctions and more—no matter where you are.',

    // Place Bid
    absenteeBidLimitExceededError: 'Bid amount {bidAmount} exceeds bid limit remaining {bidLimitBalance}.',
    completeAccountToIncreaseBidLimit:
        'To place a max bid over $100, request a higher bid limit, then add your payment & shipping details to increase your chances of being approved.',
    absenteeBidWasRounded: 'Your bid has been rounded to the closest available bid increment.',
    enterCustomBid: 'Custom Bid',
    well_send_you_an_email: "We'll send you an email once you've been approved to bid.",
    reserve: 'reserve',
    reserve_not_met_bidder_message: "Increase your bid to meet the auction house's minimum price.",

    reserve_dispute_popover_text:
        'The reserve is the minimum price the auction house will accept. In order to win, a bidder must meet or exceed the reserve and have the highest bid.',
    leadingBidder: {
        approved: {
            message:
                'The auctioneer is now reviewing your bid. You will be notified as soon as you get approved. It usually takes less than 24 hours.',
            timedPlusMessage:
                'Please note this is a Timed+ Auction where each lot has its own designated bidding end time. There is no live auction window when lots start closing. Maximize your chance at winning by returning to this lot at the bidding end time indicated. Learn more about {link}.',
        },
        pending: {
            message:
                'The auctioneer is now reviewing your bid. You will be notified as soon as you get approved. It usually takes less than 24 hours.',
            timedPlusMessage:
                'Please note this is a Timed+ Auction where each lot has its own designated bidding end time. There is no live auction window when lots start closing. Maximize your chance at winning by returning to this lot at the bidding end time indicated. Learn more about {link}.',
        },
        thankYou: {
            message: 'Thank you for the bid!',
            timedPlusMessage: "Thank you for the bid! We will notify you if you're outbid.",
        },
    },

    close: 'Close',
    doItLater: 'Do It Later',
    buyers_premium: "Buyer's Premium",
    premium_info:
        "If you win, you agree to pay a Buyer's Premium of up to {percentage}% and any applicable taxes, duties, and fees as described in this auction's {link}",
    premium_info_no_premium:
        "This auction has no Buyer's Premium! If you win, you agree to pay any applicable taxes, duties, and fees as described in this auction's {link}",
    subtotal: 'Subtotal',
    subtotal_with_buyers_premium: "{subtotal} with buyer's premium.",
    finalPriceIncludesPremium: 'Final price includes buyer’s premium.',
    finalPriceIncludesPremiumMobile: 'Final price incl. buyer’s premium.',
    your_maximum_bid: 'Your Maximum Bid',
    test_max_bid: 'Maximum Bid',
    new_maximum_bid: 'New Maximum Bid',
    all_bids_must_be_approved: 'All bids must be approved by the auctioneer.',
    someoneHasOutbidYou: 'Someone Has Outbid You',
    youCanStillWin: {
        body: 'Increase your bid to take the lead.',
        title: 'You can still win!',
        timedPlus: {
            body: 'Please note this is a Timed+ Auction where each lot has its own designated bidding end time. There is no live auction window when lots start closing. Learn more about {link}.',
            title: 'You can still win! Increase your maximum bid and return to this lot at the bidding end time indicated to secure your position as the leading bidder.',
        },
    },

    terms_of_sale: 'Terms of Sale',
    free_shipping_info:
        'This auction offers free shipping to the contiguous US. Ask auctioneer about international & combined shipping rates.',
    flat_shipping_info:
        'This auction offers flat-rate shipping to the contiguous US. Ask auctioneer about international & combined shipping rates.',

    // Retract Bid
    retract: 'Retract',
    retract_bid: 'Retract Bid',
    retract_long:
        'We understand that sometimes things come up; however, retractions are disruptive. LiveAuctioneers may suspend bidders who frequently retract bids.',
    retract_why: 'Why are you retracting your bid?',
    retract_confirm: 'Your bid has been retracted',
    retract_dismiss: 'Back to browsing',
    tooLateToRetractBid: "Sorry, it's too late to retract this bid",
    learnMoreAboutRetractionsLink: 'FAQ page',
    learnMoreAboutRetractions: 'To learn more about bid retraction, please go to our {faqLink}.',

    //  password
    set_new_password: 'Set a new password.',
    success: 'Success',
    set_password: 'SET PASSWORD',
    check_your_email: 'Check your email.',
    current_password: 'Current Password',
    new_password: 'New Password',
    please_enter_email_below: 'Please enter your email below.',
    send_reset_password: "We'll send you a link to reset your password.",
    email_address: 'Email Address',
    address: 'Address',
    default: 'DEFAULT',
    addNewAddress: 'Add New Address',
    your_password_length: 'Your password must be at least 6 characters.',
    your_password_reset: 'Your password has been successfully changed.',
    follow: 'Follow',
    following: 'Following',
    back_to_browsing: 'Back To Browsing',
    back_to_bid: 'Back To Bid',
    bid_error:
        "We're sorry, your bid was not successfully placed because of an error we encountered. Please place it again.",

    confirm_changes_password: 'Please enter your password below to confirm your changes.',
    your_changes_have_been_saved: 'Your changes have been saved.',

    // Auction Results
    auction_results_page_title: 'Search Auction Price Results - Live Auctioneers',
    auction_results_page_description:
        'LiveAuctioneers’ Auction Price Results Database is a free research tool for those who want to buy or sell. Access 29 million art, antique, jewelry, furniture, collectible, and fashion results that were auctioned off from thousands of global auction houses.',
    auction_results_description:
        'LiveAuctioneers’ Auction Price Results Database is a free research tool for those who want to buy or sell. Access 29 million art, antique, jewelry, furniture, collectible, and fashion results that were auctioned off from thousands of global auction houses. Search by keyword and refine by auction house, location, origin, style & period, creator, materials, techniques, and more to research and determine the value of items in your existing or future collection. Auction results are updated daily with new hammer prices so you can always access recently sold to historical results dating back to 1999. Whether you’re a collector wanting to know what price to pay; a consignor looking to learn the value of your property; an appraiser or estate office trying to evaluate a client’s possessions; or a journalist researching a given subject, our database is a valuable tool.',
    search_auction_price_results: 'Search Auction Price Results',
    see_auction_price_results_for: 'See {token} Auction Price Results',
    price_guide_results_how_much: 'How much is your',
    price_guide_results_worth: 'worth?',
    price_guide_results_value_of: 'Learn the market value of your',
    price_guide_results: 'Prices and Auction Results',
    price_guide_prices: 'Price Results',
    price_guide_results_in: 'prices and auction results in',
    price_guide_results_research: 'Research',
    price_guide_title: 'Prices -',
    follow_search_tooltip:
        'Get email alerts when new items matching your search are listed.{br}{br}Matched items will also appear in your Favorites manager, under Followed Searches.',

    // Register
    back: 'Back',
    confirm: 'Confirm',
    have_a_question_about_terms: 'Have a question about the terms? {askLink}',
    auction_terms_of_sale: 'Auction Terms of Sale',
    terms_and_condtions_of_sale: 'Terms and Conditions of Sale',
    notify_me_new_auctions: 'Notify me when {sellerName} lists new auctions.',
    you_must_agree_to_the_terms: 'You must agree to the terms of sale.',

    // Send Seller Message
    to: 'To',
    subject: 'Subject',
    message_the_auction_house: 'Message the Auction House',
    message_the_auction_house_subTitle:
        'Request an increase or removal of your bid limit. Please note, the auction house will review your request and respond accordingly. There may be a delay depending on their policies.',
    message_registration_declined:
        'I would like to bid in this auction, but my request to bid was declined. Please let me know how I can get approved to bid.',
    message: 'Message',
    send_message: 'Send Message',
    you_must_enter_a_message: 'You must enter a message.',
    looks_like_something_went_wrong: 'Looks like something went wrong on our end. Please try again.',
    message_sent: 'Your message has been sent',
    registrationApprovalRequest: 'Registration Approval Request',
    messageSentTitle: 'Message Sent!',
    messageSentSubtitle: 'The auction house will get back to you as soon as possible.',

    // Submit Consignment Modal
    consignment_item_description:
        'Include details like what is it made of, who made it, when, the items condition, included documentations, marks or signatures.',
    item_desc_too_short: 'Your item description should be at least 30 characters',
    why_consign_with: 'Why Consign with {sellerName}?',
    licensed_auctioneer: 'Licensed auctioneer & expert appraiser',
    reach_millions: 'Reach millions on LiveAuctioneers',
    discover_the_value: 'Discover the value of your item',
    consign_with: 'Consign with {sellerName}',
    click_to_upload: 'Click to Upload Photos',
    tell_us_about_your_thing: 'Tell us about your item(s).',
    add_photos: 'Add Photos',
    add_photos_description: 'Please submit up to 5 photos. Photos are very important for assessing the value.',
    thank_you: 'Thank You',
    consignment_review_notice:
        '{sellerName} aims to review each and every piece that passes through our inbox. If there is an interest, we may reach out in a few business days',
    keep_browsing: 'Keep Browsing',

    // Seller
    auction_ended: 'Auction Ended',
    past: 'Past',
    upcoming: 'Upcoming',
    about_auctioneer: 'About Auctioneer',
    general_question: 'General Question',
    past_auctions: 'Past Auctions',
    starts: 'Starts {startDate}',
    endsFrom: 'Ends from {endDate}',
    upcoming_auctions_from: 'Upcoming Auctions from {name}',
    past_auctions_from: 'Past Auctions from {name}',
    search_upcoming_items: 'Search upcoming items',
    record_results_for: 'Record Auction Results for {name}',
    consign_item: 'Consign Item',
    see_all_price_results: 'See all price results',
    follow_this: 'Follow this',
    follow_updates: 'Follow Updates',
    following_updates: 'Following Updates',
    no_upcoming_auctions_message_not_followed:
        '{name} has no upcoming auctions. {cta} auctioneer to hear about new auctions.',
    no_upcoming_auctions_message_followed:
        "You're following {name}. We'll email you when this auctioneer lists new auctions.",
    why_cant_i_view_the_catalog: "Why can't I view the catalog?",
    this_catalog_is_an_auction_preview:
        'This catalog is an Auction Preview and does not contain any lots. To be updated when it does have lots clicked onto the Follow Catalog Updates button above.',

    // Seller Directory
    auction_house_directory: 'Auction House Directory',
    enter_auction_name: 'Enter Auction House Name:',
    see_upcoming_auctions: 'See Upcoming Auctions Near Me',
    find_auction_houses: 'Find 5,000+ Auction Houses…',
    united_states: 'United States',
    europe: 'Europe',
    all_around_the_world: 'All Around the World',
    near_me_page_title: 'Find Auctions Near Me - Browse Online Auctions Near Me',
    seller_directory_title: 'Auction House Directory',
    loading_auctioneers: 'Loading Auctioneers',
    near_me_page_description: 'Find art, jewelry, collectibles, and estate auctions in ',
    directory_description: 'Find an auction house near me.',
    no_auctions_available_in_area: 'No Auctions Available in this Area',
    nearby_auctions_count: '{numberOfAuctions} Auctions',
    nearby_auctions_count_single: '1 Auction',
    nearby_auction_houses_count: '{numberOfHouses} Auction Houses',
    nearby_auction_houses_count_single: '1 Auction House',
    filter_by_keyword: 'Filter by Keyword',
    topRatedCount: 'Top Rated',
    freeLocalPickupCount: 'Local Pickup',

    // Near me page
    relatedAreasCarouselTitle: 'More Locations',
    auctionsIn: 'Auctions in',

    // footer
    footer: {
        recaptcha: {
            privacy: 'Privacy Policy',
            text: 'This site is protected by reCAPTCHA and the Google {tos} and {privacy} apply.',
            tos: 'Terms of Service',
        },
    },

    //tax exempt checkboxes
    taxExempt: 'I am tax exempt',

    // mobile
    get_notifications_from_favorite_auctioneers: 'Get notifications from your favorite auctioneers.',
    get_app: 'GET APP',
    pro_tip_track_all_treasures: 'Pro-tip: Track all your treasures with the LiveAuctioneers app.',

    // SEO

    // Support
    about: 'About',
    careers: 'Careers',
    auction_central_news: 'Auction Central News',
    support_center: 'Get Help',
    contact_us: 'Contact Us',
    learn_more: 'Learn More',
    winning: 'Winning',
    how_auctions_work: 'How Auctions Work',
    howTimedPlusAuctionsWork: 'How Timed+ Auctions Work',
    auction_calendar: 'Auction Calendar',
    selling: 'Selling',
    why_sell: 'Why Sell',
    become_a_seller: 'Become a Seller',
    consign_an_item: 'Consign an Item',
    auctioneer_sign_in: 'Auctioneer Sign-In',
    seller_resource_center: 'Seller Resource Center',
    copyright: '2002-{year} LiveAuctioneers. All Rights Reserved.',
    ach_terms_and_conditions: 'ACH Terms & Conditions',
    terms_and_conditions: 'Terms & Conditions',
    pr_services: 'PR Services',
    privacy_policy: 'Privacy Policy',
    cookie_policy: 'Cookie Policy',

    // Trust
    shop_with_confidence: 'Shop With Confidence',
    since_2002_liveauctioneers_has_made_items:
        'Since 2002, LiveAuctioneers has made exceptional items available for safe purchase in secure online auctions.',

    sorry_we_cant_find: "Sorry! We can't find what you're looking for.",

    // Catalog Page
    catalogPage: {
        catalogStatus: {
            timedPlusStarts: 'Bidding Starts In:',
            biddingStarts: 'Live Bidding Starts In:',
            timedPlusBiddingEnds: 'Lots Start Closing In:',
            timedPlusAuction: 'Timed+ {numberOfAuctions, plural, one {Auction} other {Auctions}}',
            aboutTimedPlus: 'About Timed+ Auctions',
            registerToBid: 'Register to bid',
            inProgress: 'Auction in progress',
            progressStatus: '{current} / {total} Lots Closed',
        },
        catalogDate: {
            done: 'Done',
            endsFrom: 'Ends from: {date}',
            startsOn: 'Starts on: {date}',
            started: 'Started: {date}',
            endingNow: 'Ending Now',
            learnMore: 'Learn more',
            timedPlusMessage:
                'In Timed+ auctions, each lot has its own bidding end time. Since Timed+ lots close independently of other lots, you can place final bids on multiple lots without having to wait for preceding lots to close. {link}',
        },
        moreFromHouseExperiment: {
            title: 'More from this auction house',
            viewAll: 'View All',
        },
        footer: {
            preview: 'Preview',
        },
        placeBid: 'Place Bid',
    },

    itemPage: {
        meta: {
            titleAppendAuction: '{title}{hasEnded, select, false { Auction} other {}}',
        },
        viewAuctionPolicy: 'View Auction Policy for Details',
        announcements: {
            title: 'Auctioneer Announcement',
            announcement: {
                title: '{title}',
                additionalInfo: 'Read More',
            },
        },
        details: {
            readMore: 'Read More',
            showLess: 'Show Less',
        },
        paymentOptions: {
            title: 'Payment',
            livePayments: 'Accepts seamless payments through LiveAuctioneers',
            inHouse: 'View Auction Policy for Details',
        },
        purchaseProtection: {
            blurb: {
                title: 'Bid confidently with Purchase Protection',
                body: "This item is guaranteed to match its description, or you'll get your money back within 5 days of delivery.",
                learnMore: 'Learn More',
            },
            modal: {
                title: 'LiveAuctioneers Purchase Protection',
                subheader: 'How It Works',
                body: {
                    appraisalGuarantee:
                        'All photos, descriptions, and estimates are prepared by a <bold>fully certified expert and appraiser</bold>.',
                    authenticityGuarantee: 'Every item in this sale is <bold>guaranteed authentic</bold>.',
                    refundGuarantee:
                        "If an item doesn't match its description, <bold>notify Jasper52 within 5 days for a full refund</bold>.",
                },
            },
        },
        shippingOptions: {
            title: 'Shipping & Pickup Options',
            shopMoreFromSeller: 'See More Items From This Jasper52 Seller',
            shippingMethod: {
                calculating: 'Calculating...',
                [ShippingMethods.LiveShipping]: {
                    pendingQuote: {
                        title: 'Ship with LiveAuctioneers {tooltip}',
                        additionalInfo: 'Estimated rate will be available soon',
                    },
                    quote: {
                        title: '${estimate} shipping with full insurance {tooltip}',
                        additionalInfo: 'To {location}',
                    },
                    noQuote: {
                        title: 'Ship with LiveAuctioneers {tooltip}',
                        additionalInfo: 'Check Shipping Rate',
                        genericError: 'Rate is currently unavailable, please {contactUsLink}',
                        genericErrorContactUs: 'contact us',
                        badZipCodeError: "Invalid Postal Code or we don't have a quote for this location",
                    },
                    showHidePriceExperiment: {
                        title: 'Ship with LiveAuctioneers {tooltip}',
                        additionalInfo: "You'll receive a shipping quote after you win",
                    },
                    unavailableQuote: {
                        title: 'See Policy for Shipping {tooltip}',
                    },
                    rateModal: {
                        title: 'Enter Your Location',
                        additionalInfo:
                            'For bidders outside of the US, your shipping quote will be available after you win.',
                        countrySelectLabel: 'Country',
                        usPostalCodeLabel: 'Zip Code',
                        postalCodeLabel: 'Postal Code',
                        submit: 'Submit',
                        close: 'Close',
                    },
                    customShippingRateModal: {
                        questions: 'Questions about shipping?',
                        [CustomShippingRateModalPages.CalculateQuoteForm]: {
                            title: 'Enter Your Location',
                        },
                        [CustomShippingRateModalPages.TooExpensiveForm]: {
                            title: 'Request Custom Shipping Quote',
                            additionalInfo:
                                'Due to special shipping requirements, the shipping quote for this item will be provided upon request. Please confirm the info below and submit your request.',
                            fields: {
                                item: {
                                    label: 'Item',
                                    name: 'Lot {lotNumber}: {title}',
                                },
                                postalCode: {
                                    label: 'Your ZIP Code',
                                },
                                email: {
                                    label: 'Your Email',
                                },
                                countrySelect: {
                                    label: 'Country',
                                },
                            },
                            request: 'Request Shipping Quote',
                        },
                        [CustomShippingRateModalPages.TooExpensiveRequestSuccess]: {
                            title: 'Your Request Has Been Sent!',
                            additionalInfo: 'You should receive your shipping quote soon.',
                            close: 'Close',
                        },
                    },
                    tooltip: {
                        title: 'Ship with LiveAuctioneers',
                        insured: '{checkmark} All items are fully insured',
                        packingHandling: '{checkmark} Professional packing & handling',
                        customerSupport: '{checkmark} Dedicated customer support',
                        body: 'LiveAuctioneers proudly partners with top-rated carriers who are highly experienced with auction shipments. The quote may change based on the final item price, as higher item values requires higher insurance.{br}{br}Questions about shipping?{br}{packagingSupportEmail}',
                    },
                },
                [ShippingMethods.HouseShipping]: {
                    noQuote: {
                        title: 'Ship with auction house',
                        additionalInfo: "You'll see the shipping quote after you win",
                    },
                    flatShipping: {
                        title: '${flatShippingAmount} shipping in the US',
                        additionalInfo: 'Shipped by auction house',
                    },
                    freeShipping: {
                        title: 'Free shipping in the US',
                        additionalInfo: 'Shipped by auction house',
                    },
                },
                [ShippingMethods.Pickup]: {
                    available: {
                        title: 'Pickup in {location}',
                    },
                    unavailable: {
                        title: 'Pickup is not available for this auction',
                        additionalInfo: 'Item will be shipped from {location}',
                    },
                },
                [ShippingMethods.BidderShipping]: {
                    title: 'Arrange your own shipping',
                    additionalInfo: 'How Does It Work?',
                    infoModal: {
                        title: 'Self-Arranged Shipping',
                        description:
                            'You organize the shipment of your item(s).{br}{br}After you win, select "Self-Arranged Shipping" as your shipping method during checkout. If you pay via Autopay, it will be automatically selected for you. Next, connect with the auction house for recommended shipping companies. Finally, pay the chosen shipping company directly and they will take care of the rest.',
                        shippingPolicy: 'shipping policy',
                        close: 'Got it',
                    },
                },
            },
        },
        shippingInsurance: {
            jewelry: {
                message: 'Protect all your jewelry from loss, theft, or damage with Jewelers Mutual. {tooltip}',
                additionalInfo:
                    'LiveAuctioneers is not a licensed agent and does not sell or offer advice about insurance. Any/all decisions for protecting jewelry must be made by the consumer, following information gathering. The purchase of insurance must be done by direct interaction with an insurer or licensed insurance agent.',
            },
            getQuote: 'Get Quote',
        },
        biddingInfo: {
            timedPlus: {
                helpLinkText: 'How Timed+ Auctions Work',
                timedPlusDescription:
                    'In Timed+ Auctions, lots run independently from one another and close at their own specified times. This allows you to watch and bid on multiple lots in a single sale more easily. {helpLink}',

                [TimedPlusStatus.NotStarted]: {
                    startsDesktop: 'Bidding starts {startDate} {info}',
                    startsMobile: 'Starts {startDate} {info}',
                    description:
                        'This auction has not started yet. Bids will be accepted for this lot starting at {date}',
                    header: 'Coming soon',
                    registerForAuction: 'Register for Auction',
                    register: 'Register',
                },

                [TimedPlusStatus.PendingRegistration]: {
                    header: 'Registration pending {info}',
                    description:
                        'In order to bid in a Timed+ Auction, your registration must be approved by the auctioneer.',
                    infoText:
                        "Please allow at least 24 hours for the auctioneer to review your registration request. If you don't hear from them or need immediate approval, please {messageTheAuctionHouse} directly.",
                    messageTheAuctionHouse: 'message the auction house',
                },

                [TimedPlusStatus.Started]: {
                    countdownDesktop: 'Bidding ends in {countdown} {info}',
                    countdownMobile: 'Ends in {countdown} {info}',
                    endsDesktop: 'Bidding ends {endDate} {info}',
                    endsMobile: 'Ends {endDate} {info}',
                    endDateOnly: '{endDate} {info}',
                    countdownOnly: '{countdown} {info}',
                },

                [TimedPlusStatus.Extended]: {
                    extended: 'Extended',
                    countdownDesktop: 'Bidding extended! {countdown} left to bid {info}',
                    countdownMobile: '{countdown} left to bid {info}',
                },

                [TimedPlusStatus.Withdrawn]: {
                    header: 'Lot Withdrawn',
                    description: 'The auction house has withdrawn this item from the auction.',
                    seeSimilarItems: 'See Similar Items',
                },
            },
        },
        soldItem: {
            image: {
                alt: '{title} First Image',
            },
        },
    },

    ariaLabel: {
        searchInput: 'Search Input',
    },
    bannerTitle: '{name}: {title}',
    search: 'Search',
    sort: 'Sort',
    filter: 'Filter',
    select: 'Select',
    current_bid_with_amount: 'Current Bid: {amount}',
    lots: 'Lots',
    location: 'Location',
    contact_auctioneer: 'Contact Auctioneer',
    view: 'View',
    auction_details: 'Auction Details',
    previous: 'Previous',
    next: 'Next',
    approved_to_bid: 'Approved to Bid',
    bid_limit_approval: 'Approved to bid up to {limit}',
    you_can_bid_on_any_of_the_items: 'You can bid on any of the items in this auction.',
    timedPlusAuctionPendingBidOnAnyItems: 'You can bid on any of the items in this auction once bidding starts.',
    try_another_search: 'Please try another search term.',
    results_for: '{count} results for',
    clear: 'Clear',
    show_all_upcoming_auctions: 'Show all upcoming auctions',
    filter_buy_now: 'Buy Now Only',
    bid_status: 'Bid Status',

    // PaginateSortFilter
    paginateSortFilter: {
        // MyBids Page
        myBidsPagination: {
            filterOptions: {
                [UserBidPlacedItemsBidStatusEnum.ALL]: 'All',
                [UserBidPlacedItemsBidStatusEnum.LEADING]: 'Leading',
                [UserBidPlacedItemsBidStatusEnum.OUTBID]: 'Outbid',
            },
            sortOptions: {
                [UserBidPlacedItemsSortEnum.BID_COUNT_ASC]: 'Least Bids',
                [UserBidPlacedItemsSortEnum.BID_COUNT_DESC]: 'Most Bids',
                [UserBidPlacedItemsSortEnum.CURRENT_PRICE_ASC]: 'Price: Lowest',
                [UserBidPlacedItemsSortEnum.CURRENT_PRICE_DESC]: 'Price: Highest',
                [UserBidPlacedItemsSortEnum.ESTIMATED_PRICE_ASC]: 'Estimate: Lowest',
                [UserBidPlacedItemsSortEnum.ESTIMATED_PRICE_DESC]: 'Estimate: Highest',
                [UserBidPlacedItemsSortEnum.LOT_NUMBER_ASC]: 'Lot Number: Lowest',
                [UserBidPlacedItemsSortEnum.LOT_NUMBER_DESC]: 'Lot Number: Highest',
                [UserBidPlacedItemsSortEnum.MY_BID_ASC]: 'My Bid: Oldest',
                [UserBidPlacedItemsSortEnum.MY_BID_DESC]: 'My Bid: Most Recent',
                [UserBidPlacedItemsSortEnum.VIEWED_ASC]: 'Viewed: Oldest',
                [UserBidPlacedItemsSortEnum.VIEWED_DESC]: 'Viewed: Most Recent',
                [UserBidPlacedItemsStatusEnum.ENDED]: {
                    [UserBidPlacedItemsSortEnum.SALE_START_ASC]: 'Time: Oldest',
                    [UserBidPlacedItemsSortEnum.SALE_START_DESC]: 'Time: Most Recent',
                },
                [UserBidPlacedItemsStatusEnum.UPCOMING]: {
                    [UserBidPlacedItemsSortEnum.SALE_START_ASC]: 'Time: Starting Soonest',
                    [UserBidPlacedItemsSortEnum.SALE_START_DESC]: 'Time: Starting Furthest',
                },
            },
        },
    },

    // Add catalog to calendar button
    add_to_calendar: 'Add To Calendar',
    add_to_calendar_text: 'Add To Calendar:',

    // Search / Similar Items
    moreLikeResults: 'More Like {searchTerm}',
    you_must_enter_a_valid_zip_code: 'Enter a valid zip code.',
    search_item_count_items: '{count} items',
    searchResultsFor: '“{searchTerm}”',
    search_see_all: 'Show All',
    search_see_less: '- Show Less',
    search_distance_zip_code: 'Zip Code',
    searchChangeCountry: 'Change',
    search_sort: 'Sort by:',
    search_no_auction_house_found: 'No auction houses found.',
    search_no_country_found: 'No countries found.',
    search_no_shipping_options_found: 'No shipping options found.',
    search_no_audio_video_found: 'No audio/video options found.',
    search_no_types_found: 'No auction types found.',
    search_no_creator_found: 'No creators found.',
    search_minimum: 'min',
    search_maximum: 'max',
    search_no_results: "No luck! We couldn't find any items matching your criteria.",
    followingSearch:
        'Following Search! We will send you email alerts when new items matching your criteria are available at auction.',
    search_error_1: 'Oops! We are having temporary technical difficulties.',
    search_error_2: 'Please try refreshing the page in 10 seconds, or ',
    search_error_contact_support: 'contact our Support Team',
    search_error_3: ' if the problem persists.',
    within: 'Within',
    searchByHousePlaceholder: 'Filter Houses',
    searchByCreatorPlaceholder: 'Filter Creators',

    // TODO : Remove all non uses of these keys.
    sorts: {
        [SearchSortAndDirection.SavedMostRecent]: 'Saved: Most Recent',
        [SearchSortAndDirection.SavedOldest]: 'Saved: Oldest',
        [SearchSortAndDirection.SavedRecentFirst]: 'Saved Recent First',
        [SearchSortAndDirection.ViewedMostRecent]: 'Viewed: Most Recent',
        [SearchSortAndDirection.ViewedOldest]: 'Viewed: Oldest',
        [SearchSortAndDirection.BestMatch]: 'Best Match',
        [SearchSortAndDirection.EstimateHighest]: 'Estimate: Highest',
        [SearchSortAndDirection.EstimateLowest]: 'Estimate: Lowest',
        [SearchSortAndDirection.LotNumberLowest]: 'Lot Number: Lowest',
        [SearchSortAndDirection.MostBids]: 'Most Bids',
        [SearchSortAndDirection.NewlyListed]: 'Newly Listed',
        [SearchSortAndDirection.PriceHighest]: 'Price: Highest',
        [SearchSortAndDirection.PriceLowest]: 'Price: Lowest',
        [SearchSortAndDirection.Promoted]: 'Promoted',
        [SearchSortAndDirection.MostRecentViews]: 'Most Recent Views',
        [SearchSortAndDirection.HammerPriceHighest]: 'Hammer Price: Highest',
        [SearchSortAndDirection.TimeNewest]: 'Time: Newest',
        // ? fix referenced in `sortTypes` (OLD vs NEW)
        [SearchSortAndDirection.TimeOldest]: 'Time: Oldest',
        [SearchSortAndDirection.TimeMostRecent]: 'Time: Most Recent',
        [SearchSortAndDirection.TimeStartingFurthest]: 'Time: Starting Furthest',
        [SearchSortAndDirection.TimeStartingSoonest]: 'Time: Starting Soonest',
    },
    date_ascending: 'Date: Ascending',
    date_descending: 'Date: Descending',
    time_soonest_first: 'Time: Starting Soonest',
    time_ending_furthest: 'Time: Starting Furthest',

    // These are kind of deprecated, they should be switched to this sort key above
    name_highest_first: 'Name: Z to A',
    name_lowest_first: 'Name: A to Z',

    searchResults: {
        noResultsTitle: '0 Items Found for “{searchTerm}”',
        lookingFor: 'Looking for {suggestion}?',
        relatedTo: 'Related to {searchTerm}',
        noResultsSubtitle: 'Get Notified when New Items Arrive',
        noResultsSubSubtitle1: 'We’ve listed {count} items matching {searchTerm} on LiveAuctioneers.',
        noResultsSubSubtitle2: 'Follow this search, and we’ll alert you as soon as more arrive.',
        seeAllPriceResults: 'See All Price Results ({count})',
        or: '{suggestionLink} or {houseLink}',
    },

    search_alerts: {
        add_keywords: 'Add Keywords',
        advanced: 'Advanced',
        advanced_search: 'Advanced Search',
        search_followed: 'Search Followed',
        alert_created: "We'll let you know when matching items are listed.",
        new_search_alert: 'New Search Alert',
        filterBySearchTerms: 'Search {count} Search Terms',
        follow_search: 'Follow Search',
        placeholder: 'Add a new search term',
        add_new_search: 'Add a New Search Term',
        add_another_search: 'Follow another search',
        done: 'Done',
        no_followed:
            "You're not following any searches yet! Follow keywords to never miss out on items you're hunting for.",
        sort: {
            newlyListed: 'Newly Listed',
            startingSoonest: 'Time: Starting Soonest',
            addedNewest: 'Date Added: Most Recent',
            addedOldest: 'Date Added: Oldest',
            resultCount: 'New Results',
            searchTerm: 'Search Term: A-Z',
        },
        resultsFor: '{count} Results for "{search}"',
        resultsPlaceholder: '{count} Lots',
        resultsPlaceholderExtended: 'Search {count} Lots',
        searchLabel: 'Search:',
        viewLabel: 'View:',
        sortLabel: 'Sort:',
        view_all: 'View All',
        edit_search: 'Edit Search',
        unfollow: 'Unfollow',
        start_new_search: 'Create a Followed Search to Be Alerted of New Results',
        new_search_1: "Type what you're hunting for in the search bar above.",
        new_search_2: 'Select "Follow Search" and save precious time looking for items.',
        new_search_3: 'When new items matching you search term are listed you will be notified via email.',
        count_terms: '{count} Terms',
        confirm: {
            are_you_sure: 'Are You Sure You Want to Unfollow?',
            no_longer_notify:
                'You will no longer be notified when new items matching your search term are listed at auction. Select the button below to confirm you are no longer interested in {token}.',
        },
        sortAndSettings: 'Sort & Settings',
        singleFilter: '1 filter',
        filterCount: '{count} filters',
        mobileItemCount: '{count} new in past 24 hrs',
        newInPast24Hrs: 'New in Past 24hrs',
        dateAdded: 'Date Added',
        countSearchTerms: '{count} Search Terms',
        singleSearchTerm: '1 Search Term',
    },

    // My Auctions Page
    approvedWithCount: 'Approved ({count})',
    pending: 'Pending',
    pendingWithCount: 'Pending ({count})',
    auctions: 'Auctions',
    empty_approved_auction_message_part_1: "Looks like you aren't registered to bid in any upcoming auctions.",
    empty_approved_auction_message_part_2: '{link} you love and register to bid in the auctions to win.',
    empty_pending_auction_message_part_1: 'You have no current pending registration approvals.',
    empty_pending_auction_message_part_2: '{link} and register to bid in an upcoming sale.',
    search_for_items: 'Search for items',
    search_for_items_now: 'Search for items now',

    // My bids page
    you_are_leading: 'Leading',
    reserve_not_met: 'Reserve Not Met',
    outbid: 'Outbid',
    not_approved: 'Not Approved',
    you_won: 'You Won!',
    you_won_new_banner: 'WON',
    leading: 'Leading',
    lost: 'Lost',
    lost_new_banner: 'LOST',
    increase_max_of: 'My Maximum Bid: {bidAmount}',
    increase: 'Increase',
    enter_live_auction: 'Enter Live Auction',
    enter_live_auction_mobile: 'Enter Auction',
    no_auctioneer_name: 'No Seller Name',
    item_sold_for: 'Sold for {bidAmount}',
    view_similar_items: 'View Similar Items',
    seeSimilarItems: 'See Similar Items',
    the_item_is_damaged: 'The item is damaged or misrepresented',
    change_of_mind: 'Change of mind',
    found_another_item: 'Found another item',
    other: 'Other',
    please_explain: 'Please explain…',
    empty_upcoming_bids_message_part_1: 'Looks like you don’t have any bids in upcoming auctions.',
    empty_upcoming_bids_message_part_2: 'Let’s fix that. {link} and start bidding (and winning!)',
    empty_past_bids_message_part_1: 'Bids for items in upcoming or live auctions will be in the Upcoming Tab.',
    empty_past_bids_message_part_2: "After the auction, you'll find the items that you've bid on here.",
    empty_past_bids_message_part_3: 'Head on over to {link} and start bidding (and winning!)',
    search_for_unique_items: 'search for unique items',
    search_for_unique_items_cap_start: 'Search for unique items',

    // Followed auctioneers
    auctioneers: 'Auctioneers',
    search_auctioneers: 'Search Auctioneers:',
    upcoming_auct: 'upcoming auctions',
    unfollow: 'unfollow',
    no_upcoming: 'None',
    empty_save_message_part_1: 'Looks like you aren’t following any auction houses.',
    empty_save_message_part_2:
        "You can keep up to date with your favorite auctioneers by pressing the 'Follow' button on their auctioneer profile page.",
    empty_save_message_part_3: 'Head on over to the {link} to find auctioneers in your area.',

    // Followed searches and alerts
    manage_button_enable_notifications: 'Enable Notifications',
    never: 'Never',
    empty_recent_results_message_part_1: 'Bummer. There are currently no items that match any of your saved searches.',
    unsubscribeEmail: '{email} will no longer receive email alerts for "{alert}"',
    unsubscribeEmailAlready: '{email} is already unsubscribed from email alerts for "{alert}"',
    unfollowSearch: 'Followed search removed.',

    // Category Pages
    category_meta_at_auction: 'at auction',
    category_meta_from: 'from',
    category_meta_for_sale: 'for Sale',
    category_meta_starting_bids: ', starting bids at $1. With over',
    category_meta_lots_available: 'lots available for antique',
    category_meta_and: 'and',
    category_meta_miss_out: "upcoming auctions, you won't want to miss out.",
    category_root_description:
        'Free online bidding auctions available worldwide. Find online auctions for vintage jewelry, artwork, furniture and many other exceptional antiquities.  Available are local auctions online and pick up in-store, or arrange shipping from auctions near you. Thousands of auctions are ending every month, find your treasure.',
    category_root_title: 'Best Online Auctions | Live Auctions - LiveAuctioneers.com',

    // Follow Search
    follow_search: 'FOLLOW THIS SEARCH',
    following_search: 'Following Search',
    follow_this_search: 'Follow this search',

    category_search_page: {
        subcategories_title: 'Subcategories',
    },

    // Search Facets
    search_facets: {
        price_results_status_label: 'Price Results',
        upcoming_status_label: 'Upcoming Items',
    },
    search_facet_title_auctionHouse: 'Auction House',
    search_facet_title_F1: 'Categories',
    search_facet_title_F2: 'Origin',
    search_facet_title_countryCode: 'Location',
    search_facet_title_currentBid: 'Current Bid',
    search_facet_title_materialsAndTechniques: 'Material / Technique',
    search_facet_title_materialsAndTechniqueShortened: 'Material/Technique',
    search_facet_title_stylePeriod: 'Style / Period',
    search_facet_title_creator: 'Creator',
    search_facet_title_estimate: 'Est. Price Range',
    search_facet_title_hammerPrice: 'Sold For',
    search_facet_title_sale_type: 'Buying Format',
    search_facet_title_shipping: 'Shipping',
    search_facet_add_exclusion: 'Exclude',
    search_facet_remove_exclusion: 'Include',
    search_facet_title_topRated: 'House Rating',
    search_facet_title_payment: 'Payment Method',
    search_facet_title_locationAndShipping: 'Location & Shipping',
    search_facet_title_saleStart: 'Auction Date',
    search_exclude_this_auction_house: 'Exclude auction house',
    search_exclude_auction_house: 'Exclude Auction House',
    search_include_auction_house: 'Include Auction House',
    search_create_alert: 'Create an alert for this',
    searchCreateAlertToast: "Following search! We'll send you email alerts for similar items.",
    search_remove_alert: 'Remove search alert for this',
    searchRemoveAlertToast: "Search Alerts Removed! We'll stop sending you email alerts for similar items.",
    search_see_similar: 'See similar items',
    exclude_house_confirmation:
        'Are you sure you want to exclude {name} from your results and recommendations? You can update this later from your {link}.',
    include_house_confirmation:
        'Are you sure you want to include {name} in your results and recommendations? You can update this later using the Auction House filter when searching items.',
    support_message: 'Need help? Visit our {link}',
    seller_exclude_house: 'Exclude this auction house from my searches & recommendations',
    seller_include_house: 'Include in my searches & recommendations',

    searchTerms: 'Search "{searchTerm}"',
    searchTermsDescription: 'Search terms: {terms}',

    // Search Input
    searchInputPast: 'Search auction price results',
    searchInput: 'Search items and auction houses',
    searchInputWhiteLabel: 'Search items',
    searchInputShort: 'Search',

    // Breadcrumbs
    back_to_auction: 'Back to Auction',
    back_to_search: 'Back to Search',
    view_auction: 'View Auction',
    view_more: 'View More',
    view_less: 'View Less',

    // Item Pages Meta
    item_buy_now_title: 'Buy {itemTitle} {sellerNameAndLocationString}',
    item_buy_now_description: 'Buy Now for {buyNowPrice} or Make An Offer',
    item_sold_description: 'on {saleDate}',
    item_passed_description: 'Item was passed',

    // Storefront Page Meta
    sf_meta_title: 'Jasper52 Seller {sellerId}',
    sf_meta_description: 'Bid and buy expert-vetted {itemCount} items from this trusted Jasper52 seller.',

    // Account Settings Page
    my_profile: 'My Profile',
    profile: 'Profile',
    excluded_houses: 'Excluded Houses',
    name: 'Name',
    you_excluded_these: 'You excluded these auction houses from your search results and recommendations.',
    include: 'Include',
    preferences: 'Preferences',
    change_password: 'Change Password',
    account_update_custom_error: 'Error: {error}',
    account_update_error: "We're sorry, we were unable to update your account at this time. Please try again.",
    accountSettingsPage: {
        addMobileNumber: 'Add Mobile Number',
        getApprovedFaster: 'Get approved to bid faster with a verified mobile number.',
        verifyMobileNumber: 'Verify My Mobile Number',
    },
    // Near me page
    auctionsNearMe: {
        ariaLabel: {
            searchInput: 'Search Input',
        },
        defaultPageDescription:
            'Find auctions near an area on a map. Then browse auction catalogs from thousands of auctioneers to bid live online on art, antiques, jewelry, and collectibles.',
        defaultPageTitle: 'Find Auctions Near Me',
        locationPageDescription: 'Find art, jewelry, collectibles, and estate auctions in {location}.',
        searchLocationPlaceholder: 'Search Location...',
    },

    // Seller Testimonials page
    seller_reviews: 'Seller Reviews',
    start_selling: 'start selling',
    consign_items: 'consign items',
    what_our_clients_say: 'What our clients say about us',
    comment1_0:
        '“The New York Times, New York Post, CBS This Morning, The Huffington Post, Associated Press, The Miami Herald, Vogue Italia, Canadian-Latin American-German press agencies, print media and websites-blogs-tweets-Facebook entries worldwide… all writing, tweeting and talking about our January 19, 2013, auction and all because LiveAuctioneers PR Services let the world know about it.',
    comment1_1:
        'The result: our best auction to date, with 690 registered bidders, 568 bids, 186,000 page views, 20,000+ visitors and $330,000 in winning bids, and all that just on LiveAuctioneers.com! That doesn’t include, as a result of your PR work, the record 400+ in-house bidders, phone bidders or left bids that all contributed to a million-dollar auction. The sheer numbers, in terms of sales and bidders, were way beyond our expectations, but you gave us even more, something you can’t pay for… EDITORIAL, exposing our auction house all over the world!',
    comment1_2:
        'You shared our passion about the auction, worked hard (even on nights and weekends) and relentlessly pursued every possible PR lead. We really don’t know how to thank you; you have energized and set the new standard for Palm Beach Modern Auctions.',
    comment1_3:
        'I would recommend your services to any auction house looking to increase their sales or company presence in the auction world.”',
    comment2:
        '“Collectors all over the world really like LiveAuctioneers. The format is simple to use, and it has become a very good highway for bidding in our sales. The age of reservation about bidding online is over; it took a company like LiveAuctioneers, which has proven itself, to make that happen. We have been very pleased.”',
    comment3:
        '“LiveAuctioneers allows us to reach an entirely new audience of potential buyers around the world who can then take part in our auctions ‘live’ on the day. It has had a significant impact on the success of our business.”',
    comment4:
        '“I used to pride myself in having a strong international clientele through phone, mail and fax bidding, but with the addition of LiveAuctioneers, my worldwide client base has increased exponentially. After my last sale, I shipped merchandise to Finland, Denmark, France, Germany, Austria, England, Belgium, the Netherlands, Portugal, Spain, Mexico, New Zealand, Australia, Japan, Canada, Singapore and even to one bidder in Czechoslovakia. It’s amazing that all these people are just a mouse click away.”',
    comment5:
        '“In a nutshell, I wouldn’t be in business without LiveAuctioneers. Our auctions average a 35-40% sell-through with the site, and on many occasions we’ve approached the 50% mark online. LiveAuctioneers has brought us customers from all over the world, including some very good regular buyers in Turkey, Israel and other countries.”',
    comment6:
        '“This last sale was a huge success and the participation through LiveAuctioneers was one of, if not the, highest, so far. It was one of our higher-end sales. We’re seeing a shift in the population that typically bids with us online—a good shift.”',
    comment7:
        '“Online bidding is the staple of any successful auction these days. And having a great online bidding system, like LiveAuctioneers, is critical.”',
    comment8:
        '“Thanks so much for an awesome auction. You all have been a huge part of our business. It seems almost flawless and the bidders seem to find the process so much easier. You all have really built something valuable!”',
    comment9:
        '“Thank you for your help in getting us started. Had it not been for the assistance of your tremendously helpful staff, the auction would not have been the success that it was. Our low estimates were in the $120,000 range, and actual sales were $264,000. Not bad at all!”',
    comment10:
        '“Working with LiveAuctioneers was a fantastic experience for my nonprofit organization. As a public charity, we rely on a variety of means to raise funds. You folks helped us launch our first-ever online auction and it was a tremendous success on a few different levels. First, we exceeded our revenue goals. But perhaps equally important, we did this without eating up a lot of our small staff’s valuable time. The tools that LiveAuctioneers provided us with were invaluable and easy to use. I look forward to working with you again in the future.”',
    comment11:
        '“It’s been a week since our New Year’s auction and we have been struggling to find the right words to describe our first experience with LiveAuctioneers PR Services. Beyond expectations? Incredible? We could not believe the response, not just in the U.S. but around the world. We sold $70,000 to Russian bidders and close to $80,000 to China, in addition to the U.S. and other countries. We were on TV, radio, in newspapers and magazines, on collector sites, the Internet and on top of the search engines. Fabulous! We had over 70,000 hits on LiveAuctioneers, alone, before the auction. Beyond expectations! Just before the auction we took a late consignment and mentioned it to Catherine at LiveAuctioneers. Two days later, it was picked up by major collector sites. Incredible!”',
    comment12:
        '“Just wanted to take the time to tell you that you and your staff are doing a great job! My staff couldn’t be happier with how things are going for us with LiveAuctioneers. The new reports are fabulous. The gentlemen that handle the phones are courteous and oh so patient. Kudos.”',
    comment13_0:
        '“There is no denying that LiveAuctioneers has helped our auction company expand our business. Since we started using their online auction service, our prices have only increased. We have seen thousands of people look at our auctions and have received hundreds of absentee bids, and this is just for one auction! Having several of these auctions has only proved that we are reaching a broader audience than we could have ever imagined.',
    comment13_1:
        'We just held a ‘country store-style’ auction a few weeks ago. Many items sold to online bidders for 2x or 3x the value that we could have gotten here at our auction house. Some items had 30-60 bids; the majority of the bidders were online. It is hard to believe the whole world is watching your auction house, but it is true. For this sale alone, we had hundreds of bidders from all across the U.S. and various countries. Working with LiveAuctioneers has been a great experience. With just a little extra work, our auction company is now known across the world.”',
    comment14:
        '“It’s now the third live auction we have done with LiveAuctioneers, and all we can say is: thank you! Thank you for your professional and fast support, thank you for your effort to improve and adjust your system to the needs of your customers. It’s good to have real people on the phone when you need them and it’s good to get an answer no matter how dull the question. Life would be easier on the web and a better place if there were more companies like LiveAuctioneers.”',
    comment15:
        '“It was an immense pleasure to have LiveAuctioneers help us through our first live auction 5 months ago. They are a benchmark for what excellent support should be.”',
    comment16:
        '“Stout Auctions has used LiveAuctioneers numerous times and we have been exceedingly happy with the results and the service they have offered. One improvement in particular convinced me to write this, and that service is the new invoicing system. No one else has an invoicing system like LiveAuctioneers; it literally saves me a full day of sorting out buyers, shipping costs, emails etc. Keep this up and you’re going to have to buy eBay!”',
    comment17:
        '“I have just been reviewing the new bidder approval process for our current auction. I must say that I am always stunned at how well LiveAuctioneers pioneers this industry we all love. This approval process is ingenious. I am quite sure that a tremendous amount of time and resources were poured into creating this option to assist the auction houses. I am proud to have our auction house as one of your clients. Thank you for breaking down the walls for all us brick & mortar auction galleries who just simply cannot do what you have done, and have done better than anyone else. Continue to educate the world about the joy of auctions and you will always have my support.”',
    comment18:
        '“After using other service providers with online auctions (via eBay and others), we are very pleased with LiveAuctioneers. The service has been great, the back-end administration features are excellent, and the ease of use is appreciated as well. We are most pleased with the post-auction reports and the detailed item reports, allowing us to tweak item descriptions to find the most buyers. We’ll continue to use LiveAuctioneers as our service provider for our online auctions. Thanks!”',
    comment19: '“Your entire company is nothing short of fantastic!“',
    comment20_0:
        '"We handle multiple dozens of specialty properties across the country at any given time – from investment and development land and commercial properties to luxury residences and collectible personal property – and working with a reliable online bidding technology provider has been crucial for us in operating efficiently and delivering value to our clients.',
    comment20_1:
        'From the moment we started working with LiveAuctioneers, we have seen tremendous growth in our personal property sales, and thanks to their expansive bidder audience, incredible client support team, and innovative technology, we can confidently offer the highest level of auction services to our clients."',
    comment21:
        '"Our family auction company recently had the pleasure to work with LiveAuctioneers for the first time. Amidst a jittery public and uncertainty due to COVID-19, we were hesitant to proceed with an online auction since we had conducted mainly on-site, in-person auctions, but LiveAuctioneers reassured us that traffic and sales were increasing exponentially, despite the Pandemic. With their tremendous support, we received targeted webinar training and personalized assistance as we navigated all aspects of their online platform in order to conduct a smooth-running sale: from auction listing to bidder approval, invoices, payments, and their Live-to-eBay program. On March 29th, our Eclectic Designer’s Connecticut Estate Auction debuted online-only, and by far, exceeded our expectations. We are extremely grateful to the entire team for their guidance and expertise. We highly recommend LiveAuctioneers to any auction company transitioning to online-only auctions!"',

    // Privacy Policy Page
    privacyPolicy: {
        title: 'ATG Privacy Policy',
    },

    // Cookie Policy Page
    cookiePolicy: {
        title: 'LiveAuctioneers Cookie Policy',
        date: 'Effective as of September 3, 2020',
    },

    // Features Page
    features_and_services: 'Features & Services for Auctioneers',
    feature_blurb:
        'Live online auctions deliver a powerful trifecta: the potential of thousands of new customers, increased average hammer prices and dramatically fewer unsold items. Using LiveAuctioneers tools and features, you can track and analyze performance while tapping into the global Internet marketplace to build your business:',
    live_bidding: 'Live Bidding',
    live_bidding_blurb:
        'This allows bidders to place bids at the same time as the auction is actually taking place. The process is similar to telephone bidding, but much faster. Bidders can bid online using their computer, or via iPhone, iPad or Android apps.',
    absentee_bidding: 'Absentee Bidding',
    absentee_bidding_blurb:
        'Once visitors have found an item that interests them, they can leave a bid online. A simple bidding form on the item description page allows them to leave a maximum bid before the sale starts. The bid is recorded and presented to the auctioneer via the live console when the auction starts.',
    auctioneer_dashboard: 'Auctioneer Dashboard',
    auctioneer_dashboard_blurb:
        "The go-to for everything involving a LiveAuctioneers account. From step-by-step auction set-up to bidder registration and approval, it's all organized, along with email messaging, access to marketing services and helpful guides and forms, just a click away.",
    our_network: 'Our Network',
    our_network_blurb:
        'Our live network runs on multiple server clusters located in two different regions of the US to ensure redundancy. Our East Coast and Southern US data centers have fully redundant battery power, generator back-up and massive connectivity, utilizing such reliable network providers as Level 3, Global Crossing and Congent. A team of technologists dedicated to network and server maintenance ensures the reliability of a complex custom system configuration to handle millions of live bidding transactions daily.',
    catalog_set_up: 'Catalog Set-up',
    catalog_set_up_blurb:
        'Item descriptions, images, estimated selling prices and contact details encourage visitors to view your catalogs before registering to bid. After a catalog has been published on LiveAuctioneers, registered bidders can view items and leave absentee bids, or wait to bid live on the day of the sale.',
    bidder_management: 'Bidder Management & Approve, Block, Set as Regular',
    bidder_management_blurb:
        'This feature enables your company to review and approve bidders based on past online activity. It can be done manually or with set criteria, which automatically approves bidders meeting those conditions.',
    inbox_messaging: 'Inbox Messaging',
    inbox_messaging_blurb:
        'This makes communication with bidders easy and centralized, offering you the chance to receive and reply to bidder questions throughout the auction process. All bidder conversations are threaded, linked to bidder profiles, and can be sorted info folders.',
    statistics_tracking: 'Statistics Tracking',
    statistics_tracking_blurb: 'Tracks how many times each item was viewed, number of visitors and referring URLs.',
    self_service_upload: 'Self-Service Upload',
    self_service_upload_blurb:
        'Makes it possible for you to upload images and catalogs directly into the LiveAuctioneers system 24/7.',
    automated_online_console: 'Automated Online Console',
    automated_online_console_blurb:
        'The automated console has the same look and feel of a standard auction, with the key added benefit of running an unmanned online auction, if you prefer.',
    end_of_auction_report: 'End-of-Auction Report',
    end_of_auction_report_blurb:
        'A performance report sent via email with critical statistics: sales totals, visits, hits, bidder registrations and more from a recent auction.',
    live_items_widget: 'Live Items Widget and Live Catalog Widget',
    live_items_widget_blurb:
        'Live Items Widget displays live items directly on the your website in a 220x200 window. Live Catalog Widget displays the entire catalog on your website in the gallery layout.',
    qr_code: 'QR Code',
    qr_code_blurb:
        'A QR code image can be added to your print ads, allowing smart phone users to go directly to your catalog.',
    staff_permission_levels: 'Staff Permission Levels',
    staff_permission_levels_blurb:
        'Permission access levels for your staff can be set within a LiveAuctioneers account.',
    weekly_newsletter: 'Weekly Newsletter',
    weekly_newsletter_blurb: 'This newsletter is sent to bidders weekly, with links to upcoming auctions.',
    help_files: 'Help Files',
    help_files_blurb:
        'This comprehensive tool helps to answer questions about running a sale online, including demo videos and detailed steps.',
    bidder_contact_and_invoicing: 'Bidder Contact and Invoicing',
    bidder_contact_and_invoicing_blurb:
        "This feature enables you to contact winning bidders with customized emails or invoices within minutes of the auction close, avoiding the time-consuming task of invoicing. LiveAuctioneers' fully automated system also allows a buyer to complete his/her purchase online.",
    paypal: 'PayPal',
    paypal_blurb: 'The platform receives automated bidder payment notifications from PayPal.',
    accounting: 'Accounting',
    accounting_blurb: 'This makes it easy to track auction accounting information.',
    white_label_platform: 'White-Label Platform',
    white_label_platform_blurb:
        'This option allows you to customize the live bidding console and run live auctions directly from your website.',

    // How it Works Page
    howAuctionsWorkPage: {
        title: 'How Auctions Work on LiveAuctioneers',
        subtitle:
            '<h6>LiveAuctioneers allows you to bid in the world’s best auctions for art, antiques, luxury and collectibles.</h6>{br}Search from hundreds of thousands of items that match what you’re looking for, or simply browse by category and creator to discover one-of-a-kind treasures.  You can place bids in advance, or bid in real-time during the live auction through our website and our { iOS } and { Android } apps. Participating in auctions on LiveAuctioneers just takes a few simple steps:',
    },
    step_one: '1. Create Your Free LiveAuctioneers Account',
    step_one_blurb:
        'Signing up for your free LiveAuctioneers account is easy and secure.  Once you’ve completed your profile on LiveAuctioneers, you can begin participating in online auctions, saving items and following searches. (Note: your information is private and secure.  Any credit card information added to your LiveAuctioneers account will not be shared with auction houses.)',
    step_two: '2. Follow Searches',
    step_two_dashboard: 'dashboard',
    step_two_blurb:
        'Looking for something specific? Type what you’re looking for in the search bar and click ‘Follow Search’ to be notified whenever new items matching your criteria are added to LiveAuctioneers. You can manage your followed searches from your { dashboard } and in the LiveAuctioneers apps for { iOS } and { Android }.',
    step_three: '3. Register for Auctions',
    step_three_blurb:
        'To actively bid in an auction on LiveAuctioneers, you must register first by clicking ‘Register for Auction’ on catalog and item pages.  You may also place absentee bids in advance of the auction time, which doubles as registration requests if you’re not already approved.{br}{br}Most auction houses approve registrations within 24 hours. Every auction house has different criteria for approval, so it’s best to register early in case they require additional information from you. Once approved, any absentee bids you may have placed will be active.',
    step_four: '4. Place Your Bids',
    step_four_blurb:
        'Each auction house handles shipping and payment differently, so before you bid, be sure to review shipping and payment policies to avoid any surprises.  Also be sure to take note of the auction’s live bidding start time. This is when live bidding begins, starting with the first lot in the auction.{br}{br}{br}There are two ways to bid:{br}{br}<h6>Absentee bidding</h6>{br}You can place absentee bids in advance of the live auction.  Our hassle-free absentee bidding system ensures <h6>you get the best prices</h6>. Be sure to submit your maximum bid amount before the live auction begins. Your bid stays at the lowest minimum amount required to keep you in the lead, and increases (up to your max amount) only if another bidder places a competing bid.{br}{br}<h6>Live bidding</h6>{br}Your chances of winning are <h6>three times greater</h6> when you bid during the live auction.  Live auctions begin at the time indicated on catalog and item pages. To bid live, simply visit the catalog or item at the indicated auction time, and click ‘Enter Live Auction’.  You will be taken to the bidding console where the live auction is taking place.{br}{br}Download our 5-star mobile app for { iOS } and { Android } so you can bid anytime, anywhere. Mobile alerts also notify you when your item is coming up for auction so you never miss an important bid.',
    step_five: '5. Win Your Treasure',
    step_five_blurb:
        'You will be automatically notified when you win an item on LiveAuctioneers.  Following the sale, you will be able to make your shipping arrangements, and you will be invoiced by the auction house for your item.  Your credit card information on LiveAuctioneers is never shared, so auction houses who do not accept online payments via LiveAuctioneers may request additional information from you.',
    step_app: 'Treasure Hunt Anytime, Anywhere',
    step_faq: 'Frequently Asked Questions',
    step_faq_descr: 'We’re here to help.  If you need more assistance, visit our full { knowledgeBase }.',

    // How it Works Page Q&A

    question_one_question: 'How do I get approved for an auction?',
    question_one_answer:
        'Each auction house has different criteria for approving bidders for their auctions. Make sure your profile information is complete and accurate; adding a credit card to your LiveAuctioneers account can help speed up the approval process. If you were declined from an auction, contact the auction house by clicking ‘Ask a question’ from the catalog or item page to inquire what additional information is needed to get approved.',
    question_two_question: 'What is a buyer’s premium?',
    question_two_answer:
        'The buyer’s premium is an additional percentage charge that the winning bidder is required to pay.  Buyer’s premiums vary from auction to auction, typically ranging from 0% to 30%. Buyer’s premiums can be found on catalog and item pages',
    question_three_question: 'How does bidding work?',
    question_three_answer:
        'You can place absentee bids in advance of the live auction.  During the live auction, you can place live bids. LiveAuctioneers’ technology prevents you from bidding against yourself, and if someone outbids you prior to the live auction, you’ll be notified via email. Note that auction houses have the ability to limit the total value of bids you’re able to place in a given auction. <learnMore>Learn more</learnMore> about bidding.',
    question_four_question: 'Can I cancel a bid?',
    question_four_answer:
        'Yes. To retract a bid, navigate to <myBids>My Bids</myBids> and select ‘Retract’ to start the retraction process. Do note that absentee bids cannot be retracted less than 2 hours prior to the listed auction start time.',
    question_five_question: 'I’m unsure about the item (authenticity, condition, etc.). What can I do?',
    question_five_answer:
        'If you have any questions regarding an item or an auction, you can contact the auction house by clicking ‘Ask a question’ from the catalog or item page.',
    question_six_question: 'How does communication with an auction house work?',
    question_six_answer:
        'If you have any specific questions regarding an item or a specific auction, you can contact the auctioneer directly through the ‘Ask a Question’ button. Communication is done through LiveAuctioneers’ messaging system, for which you can receive email and mobile notifications whenever you receive new messages. You can manage all your LiveAuctioneers conversations <messages>here</messages>.',
    question_seven_question: 'How do I know if I’ve won an item?',
    question_seven_answer:
        'You will receive an email notification and it will show up on your profile in the <wonItems>Won Items</wonItems> page.',
    question_eight_question: 'How do I pay for the item I won?',
    question_eight_answer:
        'All payment and shipping arrangements are made directly with the auction house. While auction houses typically email you an invoice with payment instructions within 24-48 hours after the end of the auction, you can also contact them directly to follow up on next steps.{br}If an auction house accepts online payments via LiveAuctioneers, invoices will contain a blue ‘Pay Now’ button where you can check out via credit card. Otherwise, you will need to either follow invoice instructions or contact the auction house for payment processing.',
    question_nine_question: 'How does shipping work? ',
    question_nine_answer:
        'As each auction house’s shipping policy may be different, we recommend reviewing the terms and conditions and the shipping policy section of each auction and item page prior to placing bids. While some auctions have their own in-house shipping ability, some may require you to facilitate the shipping process, and others can be pickup only. If shipping policies are not clear, we encourage you to reach out to the auctioneer directly through the auctioneer’s ‘Ask a Question’ button.',
    question_ten_question: 'What is the return policy?',
    question_ten_answer:
        'Each auction house has their own terms and conditions related refunds and returns, which you can view under the terms and conditions of the auction you wish to participate in. You can also contact the auction house directly through the auctioneer’s ‘Ask a Question’ button.',

    // Price Gateway
    price_gateway_card: 'See Sold Price',

    auction_terminology: 'Auction Terminology',

    intellectual_property: 'Intellectual Property',

    lets_go_treasure_hunting: "Let's go treasure-hunting.",
    find_exceptional_fine_art: 'Find exceptional fine art, collectibles, and décor in online auctions',
    //a/b test
    discover_and_win: "Join the world's best auctions for one-of-a-kind art, antiques & luxury goods.",

    dispute_status: 'Status',
    no_disputes: 'You have no disputed auctions.',
    no_won_items: 'You have no won items.',
    won_auctions_loading: 'Loading',
    ask_house: 'Contact Auction House',
    read_review: 'See Your Review',
    write_review: 'Leave a Review',
    view_order_details: 'View Order Confirmation',

    wonitems: {
        addShipping: 'Add Shipping',
        addShippingModal: {
            header: "Sorry, we can't add shipping to your order",
            bodyPrimary:
                "Unfortunately, you've missed the deadline and will not be able to ship with LiveAuctioneers at this time. We have collected all shipments and left the auction house.",
            bodySecondary:
                "If you haven't already, contact the auction house for pickup or self-arranged shipping instructions.",
        },
        checkoutError:
            "Sorry, we didn't find an invoice for catalog {catalogId}. Please view your invoice below or {support}.",
        contactLiveAuctioneers: 'Contact LiveAuctioneers',
        contactSupport: 'Contact support',
        invoice_pending: 'Invoice Pending',
        itemsByLocation: {
            location: '{itemCount, plural, one {Item} other {Items}} location: {locationLink}',
            shippedBy: 'Shipped by {carrier}.',
            trackingNumber: 'Tracking number: {trackingNumber}',
            viewTrackingHistory: 'View Tracking History',
        },
        checkout: 'Checkout',
        orderCount: '{formattedNumber} {totalOrders, plural, one {Order} other {Orders}}',
        catalog: {
            total_label: 'Total: {amount}',
        },
        badges: {
            addShippingEligible:
                'Shipping is not included. You may add shipping for {quote} before {deadline} or contact the auction house for pickup or self-arranged shipping instructions.',
            addShippingIneligible:
                "If you haven't already, arrange shipping or pickup according to the auction house's {policyLink}",
            assume_paid_submessage:
                'This invoice has been marked as paid because it is over 50 days old without being disputed',
            delivered: 'Delivered',
            delivered_submessage: 'Your order has been delivered',
            invoice: 'Invoice #{invoiceId}',
            invoice_pending_submessage: 'The auction house is preparing your invoice',
            in_transit: 'In Transit',
            in_transit_submessage: 'Your order has been shipped',
            payment_due: 'Payment Due',
            payment_due_submessage: 'Please proceed to checkout to complete payment and arrange shipping',
            dispute: 'Dispute filed',
            dispute_submessage: 'Please make a payment or contact the auction house to resolve this issue',
            dispute_resolved: 'Dispute Resolved',
            dispute_resolved_submessage: 'The auction house has removed this dispute',
            paid: 'Invoice Paid',
            paid_submessage: 'The auction house will ship you your package',
            pickup: 'Invoice Paid',
            pickup_submessage: 'Please be sure to pick this order up at: {address}',
            ready_to_ship: 'Ready to Ship',
            ready_to_ship_submessage: 'The auction house is preparing your order',
            self_arranged_shipping: 'Invoice Paid',
            self_arranged_shipping_submessage: 'Please review the auction house shipping policy and arrange shipping',
            shippingPolicy: 'shipping policy',
            shipping_status_unavailable: 'Shipping Status Unavailable',
            shipping_status_unavailable_submessage: 'Sorry, please refresh the page or try again later',
            orderComplete: 'Order Complete',
            orderCompleteSubmessage: 'Thank you for your order',
        },
        shippingStatus: {
            primary: {
                awaitingPickup: 'Awaiting Pickup',
                delivered: 'Delivered',
                inTransit: 'In Transit',
                invoicePaid: 'Invoice Paid',
                orderCompleted: 'Order Completed',
                orderCreated: 'Order Created',
                outForDelivery: 'Out for Delivery',
                packaging: 'Packaging',
                waitingForPayment: 'Waiting for Payment',
            },
            secondary: {
                arrangeShipping: 'Please review the auction house shipping policy and arrange shipping',
                carrierDeliveryFailure: 'The carrier could not deliver your order and will reach out to reschedule it',
                collectedFromHouse: 'Your package has been picked up from the auction house',
                directDeliverySuccess: 'Your package has been delivered',
                inTransit: 'Your package is on its way to you',
                markedAsPaid:
                    'This invoice has been marked as paid because it is over 50 days old without being disputed',
                packed: 'Your package has been packed',
                paymentInstructions:
                    'Your invoice has been sent with payment instructions. Be sure to check your email inbox and junk folder. Contact auction house for any question.',
                pickupDeliverySuccess: 'Your package has been delivered to the pick up location',
                returnToSender: 'Your package has been returned to the shipper',
                scheduledPickup: 'Your package has been scheduled to be picked up',
                thanks: 'Thank you for your order',
                trustedPartnerReceived: 'Our trusted shipping partner received your order',
                youWillSeeStatus: 'You will see the shipping status once the payment is processed',
            },
        },
    },

    browse_collectibles: 'Browse collectibles',

    // consignments
    consign_items_to_an_auction: 'Consign Items To An Auction',
    consign_with_trusted_auction_houses: 'Consign with Trusted Auction Houses on LiveAuctioneers',
    how_did_you_aquire: 'How did you acquire this item?',
    how_to_consign_items_to_seller: 'How to Consign Items with {sellerName}',
    consign_description_1:
        "LiveAuctioneers' mission is to connect buyers with sellers of quality goods. If you own an item(s) you would like to consign, we can help make that key connection to our more than 5,000+ auctioneers.",
    consign_description_1_seller:
        "LiveAuctioneers' mission is to connect buyers with sellers of quality goods. If you own an item(s) you would like to consign, we can help connect you with {sellerName}.",
    consign_description_2_title: 'Consignment Process:',
    consign_description_2:
        'We assist in the sale of your property by providing a secure, neutral platform in which buyers and sellers worldwide may establish contact. Please complete the online form with item details. Your item will be listed and available for review and evaluation by all auctioneers on our site. If an auctioneer is interested in your item, their staff will get in touch with you.',
    consign_description_2_seller:
        '{title} We assist in the sale of your property by providing a secure, neutral platform in which buyers and sellers worldwide may establish contact. Please complete the online form with item details. Your item will be listed and available for review and evaluation by {sellerName}. If {sellerName} is interested in your item, their staff will get in touch with you.',
    consign_description_3_title: 'Please Note:',
    consign_description_3:
        '{title} Estimates are not provided through this process. If you are seeking an estimate for your item, please contact a local auction house. LiveAuctioneers does not act as an agent for your merchandise, nor do we in any way make representations with respect to the authenticity or value of your material goods.',
    photo: 'Photo',
    value: 'Value',
    i_bought_it: 'I bought it',
    i_inherited_it: 'I inherited it',
    i_found_it: 'I found it',
    it_was_a_gift_it: 'It was a gift',
    general_description: 'General Description',
    description: 'Description',
    consignment_submitted: 'Consignment Submitted',
    submit_another_consignment: 'Consign Again',
    browse_la: 'Browse LiveAuctioneers',
    your_consignment_has_been_submitted:
        'Your consignment has been submitted. If an auctioneer is interested, they will contact you directly.',
    other_help: 'Further information that will help in the sale of your property',
    description_help:
        'Please describe your item an as much details as possible. Please include details like what is it made of, who made it, when, the items condition, included documentations, marks or signatures',
    category: 'Category',
    twentieth_century: '20th Century',
    autographs: 'Autographs',
    books: 'Books',
    commercial: 'Commercial',
    decorative_arts: 'Decorative Arts',
    entertainment: 'Entertainment',
    fine_art: 'Fine Art',
    rugs_and_carpets: 'Rugs/Carpets',
    toys: 'Toys',
    please_choose_a_photo: 'Please choose a photo to upload.',
    there_was_an_error_submitting_consignment:
        'We experienced an error trying to submit your consignment.  Please try again later.',

    ok: 'ok',
    cancel: 'Cancel',

    // auction calendar
    auction_date: 'Auction Date',
    select_date_placeholder: 'Select Date',
    auction_house: 'Auction House',
    auction_calendar_title: 'Auction Calendar - Upcoming Online Auctions & Local Auctions',
    auction_calendar_description:
        'Find all the upcoming online auctions happening today, this week or even months ahead. With over {count} auctions ending today, bid with auctions around the world or local auctions near you.',
    auctionCalendar: {
        houseRating: 'House Rating',
        searchBarText: '{count} Auctions',
        shippingOptions: 'Shipping Options',
        liveBroadcast: 'Audio / Video',
        sort: {
            title: 'Sort:',
            trending: 'Trending',
        },
    },

    catalog_card: {
        horizontal: {
            lotsClosed: '{closed}/{total} Lots Closed',
            liveNow: 'Live Now',
            bidNow: 'Bid Now',
            enterLiveAuction: 'Enter Live Auction',
        },
    },

    // bidding console
    bidding_console: {
        console: {
            button: {
                backToLiveItem: 'Back To Live Item',
                addPaymentMethod: 'Add Payment to Place Bid',
                login: 'Log In To Bid',
                signup: 'Sign Up To Bid',
                register_long: 'Register For Auction',
                pending: 'Registration Pending',
                declined: 'Registration Declined',
                suspended: 'Account Suspended',
                closed: 'Lot Closed',
                sent: 'You sent {amount} bid',
                outbid: 'Outbid! Bid {amount}',
                bid: 'Bid {amount}',
                winning: "You're Winning",
                won: 'You Won!',
                waiting: 'Still waiting on approval?',
                limit_exceeded: 'This item would exceed your bid limit of {limit}',
                approved_limited: "You're approved to bid up to {limit} and have {balance} remaining.",
                approved_limited_short: '{balance} of your {limit} bid limit remaining.',
                show_settings: 'Show Settings',
                hide_settings: 'Hide Settings',
                bidding_locked: 'Bidding Locked',
                bidding_unlocked: 'Bidding Unlocked',
            },
            conversions: {
                lot_number: 'Lot {lotNumber}',
            },
            currentLiveItem: {
                auctioneerInfo: {
                    buttons: {
                        askAQuestion: 'Ask a Question',
                        follow: 'Follow',
                        following: 'Following',
                        followingAuctioneer: 'Following Auctioneer',
                        followAuctioneer: 'Follow Auctioneer',
                    },
                },
                buyersPremium: {
                    middleLowCutOff: '{middle}% above',
                    middleCutOff: '{middle}% up to',
                    highCutOff: '{high}% above',
                    lowCutOff: '{low}% up to',
                    lowPercentage: '{low}%',
                },
                curator: {
                    curatedBy: 'Auction Curated By',
                },
                headers: {
                    buyersPremium: "Buyer's Premium",
                    condition: 'Condition',
                    description: 'Description',
                    dimensions: 'Dimensions',
                    itemDetails: 'Item Details',
                    weight: 'Weight',
                },
            },
            description: {
                estimate_range: 'Est. {low} - {high}',
                images_label: '{currentImageNumber} of {totalImages}',
                single_estimate: 'Est. {number}',
                currentBid: 'Current Bid: {amount}',
                convertedBid: 'Conversion: {amount}',
            },
            lotTracker: {
                bid: 'Bid',
                bids: 'Bids',
                liveNow: 'Live Now',
                soldFor: 'Sold for',
                passed: 'Passed',
            },
            messages: {
                ask_price_changed_to: 'Ask Price Changed: {amount}{converted}',
                bid_limit_exceeded: 'Your bid limit exceeded',
                competing_bid_amount: '{amount}{converted}: Competing Bid',
                competing_bidder_outbid_by_liveauctioneers_absentee:
                    "Competing Bidder was Outbid by Bidder {paddleNumber}'s Absentee Bid",
                last_bid_retracted: 'Last bid of {amount}{converted} retracted',
                limited_approval: 'Approved to bid up to: {limit}',
                liveauctioneers_bid_amount: '{amount}{converted}: LiveAuctioneers Bidder {paddleNumber}',
                liveauctioneers_bid_amount_absentee:
                    "{amount}{converted}: LiveAuctioneers Bidder {paddleNumber}'s Absentee Bid",
                lot_number_passed: 'Lot {lotNumber} Passed',
                lot_number_reopened: 'Lot {lotNumber} Re-Opened',
                lot_number_skipped: 'Lot {lotNumber} Skipped',
                lot_number_unsold: 'Lot {lotNumber} Unsold',
                lot_opened_asking: 'Lot {lotNumber} Opened - Ask {amount}{converted}',
                lot_sold_competing_bidder: 'Lot {lotNumber} sold for {amount}{converted} to Competing Bidder',
                lot_sold_liveauctioneers_bidder:
                    'Lot {lotNumber} sold for {amount}{converted} to Bidder {paddleNumber}',
                my_bid_amount: '{amount}{converted}: My Bid',
                my_bid_amount_absentee: '{amount}{converted}: My Absentee Bid',
                my_bid_retracted: 'Your bid for {amount}{converted} was retracted',
                paused: 'Auction Paused',
                resumed: 'Auction Resumed',
                registration_approved: 'Your registration has been approved',
                reserve_not_met: 'Reserve Not Met',
                you_been_outbid_by_liveauctioneers_absentee:
                    "You've Been Outbid by Bidder {paddleNumber}'s Absentee Bid",
                you_ve_won_lot: "You've won Lot {lotNumber}",
            },
            settingsMenu: {
                headerText: 'Settings',
                language: 'Language',
                currency: 'Currency',
            },
            missives: {
                fair_warning_lot_about_to_close: 'Fair Warning... This lot is about to close',
                going_once_going_twice: 'GOING ONCE...GOING TWICE...',
                third_and_final_warning: 'THIRD AND FINAL WARNING...',
            },
            status: {
                remaining: '{remaining} of {total} Lots Remaining',
                nextBid: {
                    text: 'My Next Bid',
                    value: '{total} Away',
                },
                nextFavorite: {
                    text: 'My Next Favorite',
                    value: '{total} Away',
                },
            },
            thumbnail_scroller: {
                next: 'Next Lots',
            },
            lockText: {
                locked: 'Unlock to Bid',
                unlocked: 'Bidding Unlocked',
            },
            mobileHeader: {
                title: '{lotNumber}: {title}',
            },
            mobileFooter: {
                itemSaves: '{savedCount}',
            },
        },
        explainer_modal: {
            title: 'Welcome to the Auction',
            subtext: 'Good luck! Please review some auction details.',
            fee_disclaimer:
                'The price of your bid does not include potential fees, such as a buyer’s premium, taxes, shipping, or processing fee',
            autopay_disclaimer: 'You will be charged the full price shortly after the conclusion of the auction',
            shipping_disclaimer: 'Shipping details can be found in this auction’s Shipping, Payment & Auction Policies',
            button: 'Got it',
        },
        wait: {
            title: 'Stay tuned. Auction will begin shortly',
            status_message: "{name} is delighted you're here!",
            login_button_text: 'Log In',
            register_button_text: 'Register for Auction',
            image_slider_text: 'Popular Lots in This Auction',
            hidden_text: 'View the Catalog',
            card: {
                title: 'Lot {lotNumber}: {title}',
                bid: 'Bid',
                bids: 'Bids',
            },
        },
        done: {
            title: '{title} has ended',
            status_message: 'Follow {name} to hear about their next auction.',
            live_catalogs_title: 'View Other Live Auctions',
            upcoming_catalogs_title: 'Upcoming Auctions',
        },
        video: {
            title: '{title} Video Stream',
            description: 'Video stream for {title}',
        },
        images: {
            title: '{title} Images',
            description: 'Images for {title}',
        },
    },
    preferredCurrencyConversion: {
        changeCurrency: 'Change Currency',
        noConversion: 'No conversion',
        popoverSummary:
            "Please note currency conversions are estimated here based on daily exchange rates which may change before the auction date. Bids and payment will be recorded and remitted in the auction's primary currency.",
        save: 'Save',
    },
    next_lot_number: 'Next Lot: {lotNumber}',
    current_bid: 'Current Bid',
    view_the_catalog: 'View the Catalog',
    lot_number_and_title: 'Lot {lotNumber}: {title}',
    number_and_title: '{lotNumber}: {title}',
    page_number_and_title: ' - Page {page}',
    lot_closed: 'Lot Closed',

    disconnected_from_auction: 'Disconnected from the Auction',
    trying_to_reconnect: 'Trying to reconnect…',

    back_to_top_button: 'TOP',

    // catalog ad
    explore_auction: 'Explore Auction',
    your_ad_here: 'Your ad here?',
    advertisement: 'Sponsored.',

    // whitelabel footer
    free: 'Free',

    // buy now experiment label
    buyNowExperiment: {
        pageTitle: 'Buy It Now',
        pageSubtitle: 'Explore items you can buy right away without competition!',
        tooltip: {
            message: 'You can search keyword here',
            button: 'Got It',
        },
    },
    // buy now
    buy_now: 'Buy Now',
    available_to_buy_now: 'Available to Buy Now',
    make_an_offer: 'Make An Offer',

    buyNow: {
        buyNowTitle: 'Request to Buy Now',
        makeOfferTitle: 'Make An Offer',
        offerSubtitle: 'Tip: Offers at 75% ({percentage}) and above have the best chance of being accepted.',
        itemPrice: 'Item Price: {price}',
        offerSubmit: 'continue',
        addressSubtitle:
            'This one-of-a-kind item is almost yours! Please confirm your shipping address so we can quote shipping costs.',
        addressSubmit: 'continue',
        formSubtitleShort: 'Our specialists will email {email} within 24 hours to complete your order.',
        formSubtitleLong:
            'Our specialists will email {email} within 24 hours to complete your order. We will include a shipping quote for your approval.',
        formSubmit: 'Submit',
        successSubmit: 'Back to Browsing',
    },

    feedback: {
        footer: 'Send Feedback',
        help_us: 'Help Us Improve Your Experience',
        help_you: 'Need immediate help? Visit our {link}.',
        improve: 'How can we improve this page?',
        email: 'Email Address',
        send: 'Send Feedback',
        link: 'Support Center',
        success: 'Your feedback has been submitted successfully',
        thanks: 'Thank you. We appreciate your feedback.',
    },

    buy_now_for: 'Buy Now for {price}',
    contact_seller_for_price: 'Contact Seller for Price',

    // Profile Address Management
    addresses: {
        // modal headers
        addNewAddress: 'Add New Address',
        editAddress: 'Edit Address',
        registerForAuction: 'Register for Auction',
        placeBid: 'Place Bid',

        // additional info
        reason: 'In order to bid online, we will need some additional information. You can update this information any time, including if you win an item.',
        reasonAbsentee100BidLimitReason: 'Please add your shipping details now to submit your leading bid.',
        reasonAbsentee100BidLimitTitle: 'You’re One Step Away From Taking the Lead',
        reasonAbsentee:
            'A valid shipping address is required to place a bid, and ensures smooth shipping and checkout when you win.',

        // buttons
        save: 'Save',
        next: 'Next',
        cancel: 'Cancel',

        // extra options
        edit: 'Edit',
        remove: 'Remove',
        setDefault: 'Set as Default',
    },
    // Curated Collection Page
    collection: {
        title: '{collectionLabel} on LiveAuctioneers',
    },

    // Checkout Page
    checkout: {
        errorCodes: {
            DECLINED_EXPIRED: 'Your card has expired, please add a new one.',
            DECLINED_ISSUER: "Your card was declined by your card's issuer, please contact them",
            DECLINED: 'Your card was declined',
            EXISTING_TRANSACTION:
                'Payment may have already been processed for this invoice. Please check your email for your receipt before reattempting payment.',
            INSUFFICIENT_FUNDS: 'Insufficient funds: please try using an alternative payment method.',
            INVALID_ACCOUNT:
                'Selected payment method is invalid, check to make sure this is an up to date card or bank account',
            INVALID_ADDRESS: "Billing address doesn't match address on file",
            INVALID_CHARGE_AMOUNT: 'Charge amount is invalid',
            INVALID_MERCHANT: 'Payment method not supported for this house',
            INVALID_METHOD: 'Payment method not supported for this house',
            INVALID_ROUTING_NUMBER: 'Invalid routing number provided',
            INVALID_SECURITY: 'Security check for this payment failed, check CVV/AV.',
            INVALID_VALUE:
                'There was an unexpected value processing your payment, if the problem persists please contact support', //we should never see this, but just incase
            INVALID_VALUE_TOTAL: 'The total value given is invalid.',
            NO_CARD_FOUND: 'No card was found to be charged, please refresh and try again.',
            UNEXPECTED_ERROR:
                'There was an unexpected error processing your payment, if the problem persists please contact support',
        },
        general: {
            notPayable:
                'Checkout is unavailable at this time. If you have questions, please email our team at {email} with the subject line "Invalid Checkout"',
            notPayableNonLAP:
                'This checkout is no longer payable, as part of the balance has been paid outside of LiveAuctioneers. To arrange payment, please email our team at {email} with the subject line "Expired Checkout - Arrange Payment"',
            learnMore: 'Learn More',
        },
        back: 'Return Home',
        stepEdit: 'Change',
        page: {
            title: 'LiveAuctioneers Secure Checkout',
            description:
                'Get in on the auction — bid LIVE online on art, antiques, jewelry and collectibles. Find online auctions from around the world at LiveAuctioneers.',
        },
        shipping: {
            title: '1. Shipping Address',
            save: 'Continue to Shipping Method',
            pickup: 'Local Pickup',
        },
        shippingMethod: {
            title: '2. Shipping Method',
            save: 'Continue to Payment',
            quoteError400:
                'We are unable to determine a shipping price. Please make sure you are using a valid, verified shipping address.',
            quoteError500: 'We are unable to determine a shipping price. Please refresh the page and try again.',
            method: {
                A: 'Arranged by LiveAuctioneers',
                laShippingDetails:
                    'Your {numberOfItems, plural, one {item} other {items}} will be fully insured and delivered in approximately {days} business {days, plural, one {day} other {days}} by {shippingProvider}.',
                laShippingDetailsNoTransitDays:
                    'Your {numberOfItems, plural, one {item} other {items}} will be fully insured and delivered by {shippingProvider}.',
                P: 'Local Pickup',
                localPickup: 'Local Pickup',
                pickupDescription: 'I will pick this up at {locationStreet}{locationState}.',
                self: 'Self-Arranged Shipping',
                selfDescription: 'I will review the shipping terms and book my own shipping.',
                pricesVary: 'Prices will vary',
                free: 'Free',
                H: 'Arranged by Auction House',
                inHouseDescription: '{houseName} will ship my items. Delivery date will vary.',
                requestShippingQuote: 'Contact {houseLink} to request a shipping quote.',
            },
            popovers: {
                title: 'How It Works',
                laShip: 'One of LiveAuctioneers’ trusted shipping partners will pick up your item from the auction house and deliver it to your door. Pricing is based on the size of the package and where it is being shipped to. After checkout, you’ll receive an email with tracking information.',
                houseShip:
                    '{houseName} has determined the cost of shipping and will either ship your items themselves, or work with a shipping partner such as UPS or FedEx. Since shipping will be handled by the auction house, we can’t provide you a delivery date right now, however the house may provide tracking information after you check out.',
                houseShipPending:
                    '{houseName} has not yet added the cost of shipping to your invoice. If you choose this option, the auction house may contact you to collect shipping fees or may require you to arrange your own shipping. Please contact the auction house for more information.',
                selfShip:
                    'After checkout, you will need to arrange for a shipping provider to handle the delivery of your items. Please review the auction’s terms and conditions for further details.',
                localPickup:
                    'You will pick up your items at {location}. You can arrange the details of your pickup directly with the auction house after checkout.',
            },
        },
        payment: {
            addCreditCard: 'Add New Card',
            addBankAccount: 'Add New Bank Account',
            ach: 'Bank Transfer (ACH)',
            achFees: 'Save {processingFee} in fees',
            requiredAch:
                'To ensure secure payment, {house} requires Bank Account (ACH) payment on all invoices over {amount}. Please select a bank account or add one if you do not have one on file. This process should only take a few minutes.',
            requiredAchAll:
                'To ensure secure payment, {house} requires Bank Account (ACH) payment on all invoices. Please select a bank account or add one if you do not have one on file. This process should only take a few minutes.',
            creditCards: 'Credit / Debit Card',
            zipError:
                'Unfortunately you are not eligible for installments with Zip for this purchase. Please select another payment method',
            zipPaymentsPlan: 'Four payments starting at {amount} with',
            zipPaymentsPlanShort: 'Four payments of {amount}',
            zipInfo:
                "Upon completing checkout, you'll be taken to Zip to finish paying for your order. A fee may apply.",
            title: '3. Payment',
            highRisk: {
                editRegulated:
                    'Due to the regulated nature of one or more items in this purchase, we ask that you verify your payment by re-entering your payment information.',
            },
            save: 'Continue to Review',
        },
        readOnlyTitle: 'Order Confirmation',
        title: 'Checkout',
        confirmationSubtitle: 'Your order has been successfully processed.',
        trust: {
            safety: 'Safety & Security',
            pcidds: 'Our Payment provider meets the strictest requirements of PCI DSS.',
            yourCardInfo: 'Your information...',
            guarantee1: 'never hits our servers',
            guarantee2: 'is completely encrypted',
            guarantee3: 'is never passed to an auctioneer',
        },
        invoice_summary: {
            achDiscount: 'ACH Discount',
            title: 'Order Summary',
            payments_title: 'Payments',
            items_total: 'Items Total',
            buyers_premium: "Buyer's Premium",
            sales_tax: 'Sales Tax',
            processing_fee: 'Online Payments Fee',
            shipping: 'Shipping',
            tax_popover:
                'Online purchases are subject to sales tax, based on shipping location and state & local rates.',
            tax_exempt: 'Tax-exempt?',
            apply: 'Apply now.',
            apply_tax_exempt: 'Apply Tax-Exempt ID',
            view_tax_exempt: 'View Tax-Exempt Status',
            processing_fee_popover: 'A higher transaction fee applies to purchases of regulated goods',
            installmentFee: 'Merchant Fee for Payment Plan',
            itemsInOrder: 'Items in Order ({count})',
        },
        balance: {
            auctionHousePolicies: 'By paying for this invoice, you agree to the {terms} set by the auctioneer.',
            highRiskTerms:
                'Due to the regulated nature of one or more items in this order, the auction house may impose additional requirements in order to finalize this sale. By paying for this invoice, you agree to the {terms} set by the auctioneer.',
            liveShippingHighRiskTerms:
                'Due to the regulated nature of one or more items in this order, the auction house may impose additional requirements in order to finalize this sale. By paying for this invoice, you agree to all {shippingPolicies} and {terms} set by the auctioneer.',
            liveShippingTerms:
                'By paying for this invoice, you agree to all {shippingPolicies} and {terms} set by the auctioneer.',
            total: 'Balance Due',
            totalDueNow: 'Estimated Due Today',
            cta: 'Pay Now',
            checkoutWith: 'Checkout With',
            liveShippingPartnersPolicies: 'shipping policies',
            termsOfSale: 'terms of sale',
        },
        total: 'Total',
        totalPaid: 'Total Paid',
        paymentProcessedNotification: {
            shipping: {
                cta: 'Pay for shipping',
                description: 'Payment is required to schedule collection.',
                header: 'Book Your Shipment with ARTA',
            },
            title: 'Checkout Confirmation - LiveAuctioneers',
            description: '',
            header: 'Thank you for your order',
            message: {
                bidderShipping:
                    'A confirmation email will be sent to {email} shortly. Please review the {house} shipping policy before arranging shipping.',
                houseShipping:
                    'A confirmation email will be sent to {email} shortly. Please contact {house} for shipping information.',
                liveShipping:
                    'A confirmation email will be sent to {email} shortly. Please refer to your email for tracking information.',
                pickup: 'A confirmation email will be sent to {email} shortly. Please contact {house} to arrange pickup.',
            },
        },
    },

    // terms banner
    terms_banner: 'By using LiveAuctioneers, you agree to our {tosLink}, {privacyLink}, and {cookieLink}.',

    // Consent modal
    consent: {
        agree: 'Agree',
    },

    // Seller Pages
    seller: {
        empty: 'This seller does not currently have any items for sale.',
        number: 'Seller # {sellerId}',
    },
    catalog_upcoming_description:
        '{lotsListed} lots featured in {catalogTitle} on {date} by {sellerName} in {sellerLocation}',
    catalog_upcoming_description_with_results:
        '{lotsListed} lots featured in {catalogTitle} on {date} by {sellerName} in {sellerLocation}, {coverLotsString} and more.',
    catalog_past_description:
        'See {lotsListed} prices and auction results for {catalogTitle} on {date} by {sellerName} in {sellerLocation}',
    catalog_upcoming_title: '{catalogTitle} on {date} by {sellerName} in {sellerLocation}',
    catalog_past_title:
        '{catalogTitle} {hyphenatedDate} Auction - {lotsListed} Price Results - {sellerName} in {sellerLocation}',
    // registration messaging
    registration: {
        sold_auction: {
            approved: 'You were approved to bid in this auction.',
            pending: 'Your request to bid in this auction was pending approval.',
            declined: 'Your request to bid in this auction was declined.',
        },
        autoApproval: {
            auctionStatusApproved: "You've been approved to bid.",
            followThisAuction: 'Follow This Auction',
            addToCalendar: 'Add to Calendar',
            unfollow: 'Unfollow',
            withoutRegistrationMessage:
                'Congrats, you meet the approval requirements for this auction! Place your bids without having to submit a registration request.',
        },
        contact_auctioneer: 'Contact auctioneer',
        message_auctioneer: 'Message Auctioneer',
        register: 'Register for Auction',
        declined: 'Registration Declined',
        declined_format: 'Contact {houseName} to complete your registration. {link}',
        declined_with_date: 'Your registration to bid live was declined. {contactAuctioneer} to get approved.',
        suspended_with_date: 'Your registration to bid live was declined because your account is suspended.',
        bid_status: '{strong} {extra}',
        outbid: "You've Been Outbid!",
        you_have_been_outbid: 'You have been outbid!',
        lot_open: 'This lot is open for bidding!',
        leading_bid: "You're the leading bidder!",
        auction_status_pending: 'Your registration to bid is pending approval.',
        auction_status_approved: "You're approved to bid.",
        auction_status_will_exceed: 'Bidding on this item would exceed your auction bid limit of {bidLimit}.',
        auction_status_will_met: 'You’ve reached the auction bid limit of {bidLimit}.',
        auction_status_container: '{first} {second}',
        auction_status_approved_first: "You're approved to bid up to {limit} for this auction. ",
        auction_status_approved_second: 'You can bid up to {balance} on this item.',
        auction_status_approved_not_bid: "{icon} You're approved to bid up to {limit}.",
        auction_status_request_more_limit:
            'Your available bid limit is based on your approved bid limit and the value of bids you’ve already placed in this auction. If you’re interested in bidding more, please {requestLink} or call {sellerPhoneNumber}',
        auction_status_request_text: 'Request a Bid Limit Increase',
        auction_status_request_text_alt: 'Request a Higher Bid Limit',
        auction_status_complete_profile: 'Complete Profile to Bid',
        sellerMessage: {
            subject: 'Bid Limit Increase Request',
            body: 'This is a request to increase my {bidLimit} bid limit. Please advise what next steps are required in order to proceed.',
        },
        auction_status_approved_limited:
            "You're approved to bid up to {limit} for this auction. You can bid up to {balance} on this item.",
        auction_status_not_registered: 'Get approved to bid.',
        raise_bid: 'Raise your bid to increase your chances of winning.',
        leading_after_approval: "If approved, you'll be the leading bidder!",
        pending_raise_bid:
            'Your request to bid is pending approval. In the meantime, raise your bid to increase your chances of winning. {link}',
        pending_raise_bid_tips: 'Tips for Getting Approved',
        reserve_not_met: 'Reserve Not Met',
        reserve_raise_bid: 'Raise your bid to meet the reserve and increase your chances of winning.',
        falls_below: 'Your bid falls below the reserve price for this lot.',
        approved_leading_bid_reserve_not_met:
            "Your bid has been recorded, but the reserve hasn't been met. Unless the reserve is met, the lot will not be sold.",
        issue: 'Issue with Auction Registration on {catalogTitle}',
        payment_issue: 'Question from winning bidder (Lot {lotNumber})',
        request_submitted: 'Request To Bid Submitted',
        preview_only: 'Preview Only',
        closing_today: 'Lot Closes Today',
        preview_only_description: 'This catalog is only available for preview on LiveAuctioneers.',
        preview_only_description_short:
            'This catalog is only available for preview on LiveAuctioneers. Request online bidding to notify the auction house of your interest in bidding on LiveAuctioneers.',
        lot_closed: 'Lot Closed',
        lot_won: 'You Won This lot!',
        lot_won_copy:
            'Expect an email invoice from {houseName} within 3 business days. {link} with any questions regarding payment.',
        lot_lost_copy: "Someone else placed an earlier or higher bid, or your bid wasn't approved. {link}",
        tips_for_winning: 'Tips For Winning Next Time',
        contact_this_auctioneer: 'Contact this auctioneer',
        lot_lost: 'Sorry, your bid lost.',
        no_longer_bid: 'You can no longer bid on this lot.',
        register_for_auction: 'Register for Auction',
        register_for_auction_my_items: 'Register',
        to_be_approved: 'to be approved for live bidding.',
        live_bidding_started: 'Live bidding started at {time}',
        liveMessages: {
            pending: 'Live bidding started at {today}. Your registration to bid is pending approval.',
            approved: "Live bidding started at {today}. You're approved to bid in this auction.",
            limited:
                'Live bidding started at {today}. You’re approved to bid up to {limit} and have {balance} remaining for this auction.',
            declined:
                'Live bidding started at {today}. Your request to bid was declined. {contactAuctioneer} to get approved.',
            suspended:
                'Live bidding started at {today}. Your registration to bid was declined because your account is suspended.',
            notRegistered: 'Live bidding started at {today}. Get approved to bid. {register}',
            notRegisteredOnly: 'Live bidding started at {today}. {register}',
            yesterday: '{time} yesterday',
            today: '{time} today',
        },
        newLiveMessages: {
            pending: 'Your registration to bid is pending approval.',
            approved: "You're approved to bid in this auction.",
            limited: 'You’re approved to bid up to {limit} and have {balance} remaining for this auction.',
            declined: 'Your request to bid was declined. {contactAuctioneer} to get approved.',
            suspended: 'Your registration to bid was declined because your account is suspended.',
            notRegistered: 'Get approved to bid. {register}',
            notRegisteredOnly: ' {register}',
            yesterday: '{time} yesterday',
            today: '{time} today',
        },
        timedPlus: {
            auction_status_approved: "You're approved to bid once the auction starts",
            auction_status_pending: 'Your registration to bid is pending approval',
            liveMessages: {
                approved: "You're approved to bid in this auction",
                pending: 'Your registration to bid is pending approval',
            },
        },
    },

    // save item button
    bidder_saved_item_count:
        '{savedCount} {savedCount, plural, one {bidder is} other {bidders are}} watching this item.',

    // watch_live_auction: 'Watch live auction',
    suspendedBidderBanner: {
        button: 'Restore Bidding',
        text: 'Action Needed: Bidding privileges are suspended.',
    },
    followedSearchCarousel: {
        header: 'New From Followed Searches',
        viewAll: 'See All',
    },
    searchFollowSuggestions: {
        altHeader: 'Popular Searches',
        header: 'Searches To Follow',
        manage: 'Add More',
        subTitle: 'Be the first to hear about new items you love.',
        follow: 'Follow',
        items: 'items,',
        followers: 'followers',
    },

    recently_viewed: 'You Recently Viewed',
    recommended_items: 'Recommended Items',
    coming_up_title: 'Coming Up For Auction',
    registration_status_label: {
        request_online_bidding: 'Request online auction',
        register_for_auction: 'Register for Auction',
        register_to_bid: 'Register to Bid',
        registration_pending: 'Registration Pending',
        catalog_only_registration_submitted: 'Bid Request Submitted',
        registration_approved: 'Approved To Bid',
        registration_declined: 'Registration Declined',
        registration_denied: 'Registration Denied',
        request_submitted: 'Request Submitted',
    },
    review_page: {
        review_your_auction: 'Rate Your Experience',
        received_items: 'Have you received your items?',
        received_item: 'Have you received your item?',
        encourage_wait:
            'We encourage you to rate your experience after receiving your items. This gives you a chance to rate shipping and item accuracy.',
        waiting_for_items:
            "If you haven't received your items and haven't heard from {name}, {sendMessage}, or {contactSupport} for help.",
        continue: 'Continue Review',
        later: 'Finish Later',
        send_message: 'send a message',
        contact_support: 'contact our Support Team',
        great: 'Great! ',
        tell_us: 'Tell us about your experience with {houseName} below:',
        yes: 'yes',
        no: 'no',
        headline_placeholder: "What's most important to know?",
        input_length_error: 'Please enter at most {limit} characters.',
        public_review: 'Write a public review for {houseName}',
        private_feedback: 'Send feedback privately (optional)',
        rated_date: 'You rated {name} on {date}:',
        error: 'Unable to process your review at this time',
        review_guidelines: {
            header: 'Public Review Guidelines',
            body: 'Your review may appear publicly alongside your city and first name. We may not publish your review if it includes personal or inappropriate content such as:',
            lastNames: 'Last names',
            addresses: 'Addresses',
            phoneNumbers: 'Phone numbers',
            email: 'Email addresses',
            trackingNumbers: 'Tracking numbers',
            profanity: 'Profanity',
            link: 'Read more review guidelines',
            mobileBody:
                'We may not publish your review if it includes personal or inappropriate content, such as: last names, addresses, phone numbers, emails, tracking numbers, or profanity.',
        },
        character_requirements: '{remaining} characters remaining.',
        fields: {
            accuracy: 'Accuracy',
            responsiveness: 'Responsiveness',
            payment: 'Payment',
            shipping: 'Shipping / pickup',
            overall: 'Overall Experience',
            feedback: 'Private Feedback for the Auction House',
            review: 'Write a Public Review',
        },
        field_detail: {
            accuracy: 'How satisfied were you with the accuracy of photos and descriptions provided by {houseName}?',
            responsiveness_1: 'Did you contact {houseName} with a question or concern?',
            responsiveness_2: 'How helpful was {houseName} in responding to your questions or concerns?',
            payment: 'How satisfied were you with the payment process?',
            shipping: 'How satisfied were you shipping or pickup process?',
            overall: 'Overall, how satisfied were you with your experience with {houseName}?',
            feedback: 'Is there anything else you would like {houseName} to know about your experience with them?',
            review: 'Is there anything you would like other bidders to know about your experience with {houseName}?',
        },
        labels: {
            headline: 'Headline',
            public_review: 'Public Review',
            private_feedback: 'Private Feedback for the Auction House',
            private_feedback_label: 'Private Feedback',
        },
        review_copy: {
            _1: 'Very Dissatisfied',
            _2: 'Somewhat Dissatisfied',
            _3: 'Neutral',
            _4: 'Somewhat Satisfied',
            _5: 'Very Satisfied',
        },
    },
    disabled_would_exceed: 'This item would exceed your bid limit of {limit}',
    //notifications
    notifications: {
        enable_browser_notifications: 'Enable Browser Notifications',
        enable_notifications: 'Enable Browser Notifications',
        get_notifications_browser: 'Browser notifications are off.',
        get_notifications_phone: 'Get notifications on your phone.',
        download_app: 'Download App',
        no_new_notifications: 'All caught up!',
        modify_your_browser_preferences:
            'Modify your browser preferences to proceed with enabling browser notifications.',
        notifications_enabled: 'Browser notifications are on.',
        delete_all: 'Clear All Notifications',
        outbid_notify: "Notify me when I'm outbid",
        just_now: 'Just now',
        get_notifications_when_outbid:
            'Stay in the lead. Get notifications on this device for all your bids, favorite lots, and sellers.',
        turn_off_notifications: 'Disable Browser Notifications',
        enable_notifications_prompt_title: 'Never miss a treasure!',
        enable_notifications_prompt_body:
            'Enable browser notifications and get updates on important items and auctions?',
        yes: 'Yes, Update Me',
        risk_it: 'No',
        get_notified:
            'Get SMS and browser notifications when you’re outbid and when your item is coming up for auction.',
        get_sms: 'Get alerted on your bids and upcoming items',
        get_seller_alerts: 'Get notified about new auctions from this auctioneer',
    },

    review: {
        learnMoreHere: 'Learn more here',
        cancel: 'Cancel',
        bidder_reviews_name: 'Bidder Reviews for {name}',
        review_count: '{count} {reviewCount, plural, one {Review} other {Reviews}}',
        latest_review: 'Latest Review',
        latest_reviews: '{count} Latest Top Reviews',
        full_page_review_title: '{start}-{end} of {count} Reviews',
        helpful: 'Helpful',
        report: 'Report',
        reported: 'Reported',
        report_abuse_title: 'Report Abuse',
        report_abuse_body:
            'If this review is inappropriate and should not be shown on LiveAuctioneers.com, let us know by submitting a report.',
        report_abuse_button: 'Report',
        report_submitted_title: 'Thank you!',
        report_submitted_body:
            'We’ve received your report and our team will investigate this review. Thanks for helping keep LiveAuctioneers a safe and friendly space.',
        report_submitted_button: 'Done',
        overall_rating_label: '{rating} out of 5.0 Stars',
        all_reviews: 'Reviews',
        verified_buyer_headline: 'Verified Buyer',
        verified_buyer_text: 'This review is written by a verified LiveAuctioneers winner.',
        page_link_button: 'See all {count} reviews',
        page_link_button_singular: 'See 1 review',
        meta: {
            title: '{houseName}, {houseLocation} - Read over {reviewsCount} Reviews from Customers',
            description: '{reviewsCount} real customer reviews of {houseName}, {houseLocation} "{reviewSnippet}"',
        },
        see_all: 'See All',
        not_enough_data: 'Not enough data',
        ratings_explanation:
            'LiveAuctioneers calculates star ratings based on ratings given, number of reviews, age of reviews, and only accepts reviews from winning bidders.',
    },
    ranking: {
        accName: 'Accurate Descriptions',
        accPopoverHeader: 'Top Rated in Accuracy',
        accPopoverText: 'This auctioneer excels at providing descriptions and photos that represent items accurately.',
        respName: 'Quick Responses',
        respPopoverHeader: 'Top Rated in Responsiveness',
        respPopoverText: 'This auctioneer excels at responding to customer inquiries in a helpful manner.',
        payName: 'Easy Payments',
        payPopoverHeader: 'Top Rated in Payments',
        payPopoverText: 'This auctioneer excels at providing customers a way to easily pay for items.',
        shipName: 'Seamless Shipping',
        shipPopoverHeader: 'Top Rated in Shipping',
        shipPopoverText: 'This auctioneer excels in offering a seamless shipping experience.',
    },
    sellerPageTabLinks: {
        auctions: 'Auctions',
        upcoming: 'Upcoming Auctions',
        news: 'News',
        past: 'Past Auctions',
        reviews: 'Reviews',
        results: 'Record Results',
    },

    // autopay
    autopay: {
        addPayment:
            'This auction uses Autopay by LiveAuctioneers. If you place a winning bid, we will charge your credit card automatically 1-3 days after your invoice is issued. for your item.',
        terms: {
            heading: 'About Autopay by LiveAuctioneers',
            body: "When you have a credit card on file with LiveAuctioneers, Autopay will automatically charge that card so that you don't need to check out. If you want to pay with an alternative payment method, contact the auction house directly to let them know. Otherwise, your card on file will be charged three days after your invoice is sent.",
        },
    },
    // instant autopay
    instantAutopay: {
        absenteeBidModal: {
            feesDisclaimer: 'Your final order total may include tax, shipping, or transaction fees',
        },
        addPayment:
            'This auction uses Autopay by LiveAuctioneers. If you place a winning bid, your card will be charged shortly after the conclusion of the auction.',
    },
    messaging: {
        title: 'Messages',
        inbox: 'Inbox',
        sent: 'Sent',
        move: 'Move to Folder',
        markUnread: 'Mark Unread',
        markRead: 'Mark Read',
        archive: 'Archive',
    },

    FormattedMessageTextTest:
        'This is a message{br}<caption>caption</caption><h1>h1</h1><h2>h2</h2><h3>h3</h3><h4>h4</h4><h5>h5</h5><h6>h6</h6><mini>mini</mini><primary>primary</primary><secondary>secondary</secondary>',

    certManagement: {
        taxes: 'Tax Exemption',
        state: 'State',
        signedDate: 'Date Signed',
        expirationDate: 'Expiration Date',
        status: 'Status',
        view: 'View',
        renew: 'Renew',
        delete: 'Delete',
        addCert: 'Add New Certificate',
        upload: 'Add Certificate',
        selectState: 'In which state are you tax-exempt?',
        taxCerts: 'Tax Exemption Certificates',
        uploadYourTaxInfo: 'Add New Certificate',
        backToTaxManagement: 'Back to Tax Management',
        manageCerts: 'Add, delete, and manage your tax exemption certificates.',
        valid: 'Valid',
        invalid: 'Invalid',
        inReview: 'In Review',
        reviewingCertificate: "We're reviewing your tax-exempt certificate.",
        invalidCertificate: 'This certificate is invalid. Please upload a new one.',
        missingCertToken: 'There has been a problem.  Please try again later',
    },

    loadMore: 'Load More',
    discoverPage: {
        meta: {
            descriptionMultipleCategories:
                'Hunt for antique & vintage {term} {l1First} and {l1Second}. {totalRecords} {term} items for online auction.',
            description: 'Hunt for antique & vintage {term} {l1First}. {totalRecords} {term} items for online auction.',
        },
        buttons: {
            shipping: 'Easy Shipping',
            us: 'US Only',
            new: 'Newly Listed',
            top: 'Top Rated House',
        },
        exploreCategories: 'Explore Categories',
        subCategory: {
            subCategoryTitle: '{title} - Most Followed',
            subCategoryLink: 'See All {title} Items',
            subCategoryTitleSpan: '{title}',
            subCategoryFollowers: '{followers} Followers',
            subCategoryFollower: '1 Follower',
        },
        sidebar: {
            allOtherCategories: 'All Other Categories',
            category: '{category}',
        },
        otherCategories: {
            title: 'All Other Categories',
            viewAllItems: 'View All Items',
        },
    },
    creditCards: {
        notAllowedToAddCards: 'Too many cards added recently, try again in 30 minutes.',
        invalidPaymentProvider: 'Invalid catalog payment configuration. Please contact support.',
    },
    zipModal: {
        disclaimer:
            'An installment fee may apply. For example, for a $335 purchase, you’d make four $85.25 payments every two weeks starting today for a 31.11% annual percentage rate and a total of payments of $341. A $6 installment fee is charged at commencement - you pay $1.50 of this fee as a prepaid finance charge when you make your initial payment today. The remaining $4.50 is included in your future payments. Actual installment fees vary and can range from $0 to $7.50 depending on the purchase price and Zip product used. Actual amount of fee for your purchase will be reflected in checkout. Estimation of installment payment and annual percentage rate excludes potential tax and shipping costs. Zip Pay Anywhere and Zip Checkout financing through Zip issued by WebBank. All loans are subject to credit approval.{br}{br}Late fees may apply, subject to eligibility. See complete {usTermsLink} for more detail. Est. payments on product pages exclude taxes, shipping and consumer fees added at checkout. Loans to California residents are pursuant to CFL license #60DBO-110414. Where indicated in the terms and conditions, loans are originated by WebBank. All loans subject to approval. Zip Co US Inc. ID 1963958 {nmlsConsumerAccessLink}',
        usTermsLink: 'US Terms',
        nmlsConsumerAccessLink: 'NMLS Consumer Access',
        here: 'here',
        title: 'Pay with Zip',
        subtitle: 'We split the purchase amount into four payments, spread over six weeks.',
        chooseZip: 'Choose Zip at Checkout',
        chooseZipSubtitle: 'Quick and easy. No hidden fees.',
        useDebitOrCredit: 'Use your debit or credit card',
        useDebitOrCreditSubtitle: 'No long forms and instant decision.',
        fourInstallments: 'Pay in four installments',
        fourInstallmentsSubtitle: 'Enjoy your purchase straight away.',
        copyright: '© {year} Zip, Inc.',
    },
    addressVerifyModal: {
        title: 'Verify Your Address',
        suggestedAddress: 'Suggested Address',
        useThisAddress: 'Use This Address',
        addressEntered: 'Address Entered',
        edit: 'Edit',
        editThisAddress: 'Edit This Address',
        selectADifferentAddress: 'Select a Different Address',
        verify: {
            subtitle: 'We found a more detailed address. Please review the updates and confirm your address below.',
            useThisInstead: 'Use This Instead',
        },
        register: {
            subtitle:
                "For the best chance at getting approved, we suggest a slightly modified address than the one on file. We've highlighted the suggested changes below.",
            useThisInstead: 'Use This Instead',
        },
        invalid: {
            subtitle:
                'It looks like you may have an invalid address. Providing a valid address helps with getting approved for auctions and ensuring seamless delivery of your items.',
            useThisInstead: 'Use This Address',
        },
    },
    partners: {
        // These are written in separate files because they are loooooong.
        nationalLogisticServices,
        packengers,
        pageTitle: '{partnerName} Shipping Policy',
        lastUpdated: 'Last updated: {date}',
    },

    absenteeBid: {
        approvedSubtotalNotice: 'Tax, shipping, or transaction fees may be added to the total after you win.',
        approvedSubmit: 'Submit Bid',
        nonApprovedSubmit: 'Next',
        subtitle: "Enter the highest amount you're willing to bid. We'll bid incrementally to keep you in the lead.",
        terms: 'Tax, shipping, or transaction fees may be added to the total after you win.',
        bid_again: 'Bid Again',
    },
    addAddress: {
        title: 'Add Shipping Address',
    },
    reviewYourBid: {
        title: 'Review Your Bid',
        subtitle: "If you win, you'll be notified with purchase instructions.",
        edit: 'Edit',
        bidInfo: {
            header: 'Your Bid',
            maxBidHeader: 'Maximum Bid',
            premiumHeader: "Buyer's Premium",
            shippingHeader: 'Shipping',
            shippingDestinationLabel: 'to {region}',
            subtotalHeader: 'Subtotal',
            terms: 'Tax, shipping, or transaction fees may be added to the total after you win.',
        },
        shippingInfo: {
            header: 'Shipping Address',
        },
        paymentInfo: {
            header: 'Payment Method',
            noCard: 'Not Added',
            autoPayTerms: "You won't be charged unless you win.",
            terms: "You won't be charged now. You will be directed to the checkout page if you win.",
            contactHouseForInstructions:
                'No payment method required at this time. If you win, the auction house will follow up with payment instructions.',
            skipPaymentVariant:
                'No payment method required at this time. If you win, you will receive an invoice to complete purchase.',
        },
        completePage: 'Submit Bid',
        conditions: {
            termsOfSale: 'Terms of Sale',
            autopay:
                'If you win an item, your credit card will be charged 1-3 days after your invoice is issued. By clicking "Submit Bid" you agree to this auction\'s {termsOfSale}.',
            instantAutopay:
                'If you win an item, your credit card will be charged shortly after you receive an invoice. By clicking "Submit Bid" you agree to this auction\'s {termsOfSale}.',
            regularTerm: 'By clicking "Submit Bid" you agree to this auction\'s {termsOfSale}',
        },
    },
    priceResultsPage: {
        viewItem: 'View Item',
        viewItemDetails: 'View Item Details',
        viewItemDetailsShort: 'View Details',
        totalLabel: 'Total Sales',
        medianLabel: 'Median Sale Price',
        rangeLabel: 'Sale Price Range',
        priceRangeLabel: '{lowPrice} - {highPrice}',
        totalItemsSold: '{totalItemsSold} Sold',
        unlock: 'Unlock',
        unknownStat: 'N/A',
        upcomingSales: 'Upcoming Sales',
        similarItems: 'Similar Items',
        shopNow: 'Shop Now',
        similarSaleHistory: 'Similar Sale History',
        unlockAllSalePrices: 'Unlock All Sale Prices',
        viewAll: 'View All',
        loadMore: 'Load More',
        auctionEndedOn: 'Auction ended on {endDate}',
        followSearch: 'Follow This Search',
        followSearchShort: 'Follow Search',
    },

    upcoming_highest_first: 'Upcoming Auctions',
    auction_curator: 'Auction Curator',
    auction_curators: 'Auction Curators',
    confirm_changes: 'Confirm Changes',
    changes_saved: 'Changes Saved',
    all: 'All',
    disputed: 'Open Disputes',
    price_highest_first: 'Price: Highest',
    price_lowest_first: 'Price: Lowest',
    estimate_highest_first: 'Estimate: Highest',
    estimate_lowest_first: 'Estimate: Lowest',
    featured_lots: 'Featured Lots',
    least_bids: 'Least Bids',
    most_bids: 'Most Bids',
    lot_number_highest_first: 'Lot Number: Highest',
    lot_number_lowest_first: 'Lot Number: Lowest',
    viewed_recent_first: 'Viewed: Most Recent',
    viewed_oldest_first: 'Viewed: Oldest',
    category_meta_shop_for: 'Shop for vintage',
    save_to_my_items: 'Save to My Items',

    ...caterwaulIntlEn,
    ...hammerLAIntlEn,
};

export default flattenMessages(en);

export type TranslationMessages = DeepPartial<typeof en>;
